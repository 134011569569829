.ui.menu.ContentHeader__Menu {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-bottom: 2px solid rgba(34, 36, 38, .1);
  border-radius: 0;
  margin-right: -16px;
  margin-left: -16px;

  .item {
    padding: 2rem 0.5rem;

    &:before {
      background: transparent;
    }

    .header {
      display: flex;
      flex-direction: column;
      font-weight: 200;
      font-size: 1.8rem;
      padding-right: 3rem;
      justify-content: center;
      align-items: flex-start;

      .header-subtitle {
        font-size: 1rem;
      }
    }
  }

  .back-button {
    padding: 8px !important;
  }
}