:root,
.rs-theme-light {
  --rs-gray-50: #f7f7fa;
  --rs-gray-100: #f2f2f5;
  --rs-gray-200: #e5e5ea;
  --rs-gray-300: #d9d9d9;
  --rs-gray-400: #c5c6c7;
  --rs-gray-500: #a6a6a6;
  --rs-gray-600: #8e8e93;
  --rs-gray-700: #7a7a7a;
  --rs-gray-800: #575757;
  --rs-gray-900: #272c36;
  --rs-primary-50: #f2faff;
  --rs-primary-100: #cce9ff;
  --rs-primary-200: #a6d7ff;
  --rs-primary-300: #80c4ff;
  --rs-primary-400: #59afff;
  --rs-primary-500: #3498ff;
  --rs-primary-600: #2589f5;
  --rs-primary-700: #1675e0;
  --rs-primary-800: #0a5dc2;
  --rs-primary-900: #004299;
  --rs-red-50: #fff2f2;
  --rs-red-100: #fccfcf;
  --rs-red-200: #faa9a7;
  --rs-red-300: #fa8682;
  --rs-red-400: #f7635c;
  --rs-red-500: #f44336;
  --rs-red-600: #eb3626;
  --rs-red-700: #d62915;
  --rs-red-800: #b81c07;
  --rs-red-900: #8f1300;
  --rs-orange-50: #fff8f2;
  --rs-orange-100: #ffdfc2;
  --rs-orange-200: #fcc690;
  --rs-orange-300: #fcb160;
  --rs-orange-400: #fa9b2f;
  --rs-orange-500: #fa8900;
  --rs-orange-600: #f08800;
  --rs-orange-700: #db8000;
  --rs-orange-800: #bd7100;
  --rs-orange-900: #945b00;
  --rs-yellow-50: #fffaf2;
  --rs-yellow-100: #ffe9c2;
  --rs-yellow-200: #ffd991;
  --rs-yellow-300: #ffca61;
  --rs-yellow-400: #ffbe30;
  --rs-yellow-500: #ffb300;
  --rs-yellow-600: #f5af00;
  --rs-yellow-700: #e0a500;
  --rs-yellow-800: #c29100;
  --rs-yellow-900: #997500;
  --rs-green-50: #eeffed;
  --rs-green-100: #c8f0c7;
  --rs-green-200: #a5e0a4;
  --rs-green-300: #82cf82;
  --rs-green-400: #65bf67;
  --rs-green-500: #4caf50;
  --rs-green-600: #37ab3c;
  --rs-green-700: #22a12a;
  --rs-green-800: #0f9119;
  --rs-green-900: #007d0c;
  --rs-cyan-50: #f2ffff;
  --rs-cyan-100: #bcf4f7;
  --rs-cyan-200: #87e6ed;
  --rs-cyan-300: #57dae6;
  --rs-cyan-400: #2acadb;
  --rs-cyan-500: #00bcd4;
  --rs-cyan-600: #00b1cc;
  --rs-cyan-700: #00a0bd;
  --rs-cyan-800: #008aa6;
  --rs-cyan-900: #006e87;
  --rs-blue-50: #f0f9ff;
  --rs-blue-100: #c5e7fc;
  --rs-blue-200: #9bd4fa;
  --rs-blue-300: #72c0f7;
  --rs-blue-400: #49abf5;
  --rs-blue-500: #2196f3;
  --rs-blue-600: #1787e8;
  --rs-blue-700: #0d73d4;
  --rs-blue-800: #045cb5;
  --rs-blue-900: #00448c;
  --rs-violet-50: #f6f2ff;
  --rs-violet-100: #d5c9f0;
  --rs-violet-200: #b6a1e3;
  --rs-violet-300: #987bd4;
  --rs-violet-400: #805ac7;
  --rs-violet-500: #673ab7;
  --rs-violet-600: #5f2bb3;
  --rs-violet-700: #531ba8;
  --rs-violet-800: #470c99;
  --rs-violet-900: #390085;
  --rs-state-success: #4caf50;
  --rs-state-info: #2196f3;
  --rs-state-warning: #ffb300;
  --rs-state-error: #f44336;
  --rs-body: #fff;
  --rs-bg-success: #edfae1;
  --rs-bg-info: #e9f5fe;
  --rs-bg-warning: #fff9e6;
  --rs-bg-error: #fde9ef;
  --rs-text-link: #1675e0;
  --rs-text-link-hover: #0a5dc2;
  --rs-text-link-active: #004299;
  --rs-text-primary: #575757;
  --rs-text-secondary: #8e8e93;
  --rs-text-tertiary: #a6a6a6;
  --rs-text-heading: #272c36;
  --rs-text-inverse: #f7f7fa;
  --rs-text-heading-inverse: #fff;
  --rs-text-active: #1675e0;
  --rs-text-disabled: #c5c6c7;
  --rs-text-error: #f44336;
  --rs-border-primary: #e5e5ea;
  --rs-border-secondary: #f2f2f5;
  --rs-bg-card: #fff;
  --rs-bg-overlay: #fff;
  --rs-bg-well: #f7f7fa;
  --rs-bg-active: #3498ff;
  --rs-bg-backdrop: rgba(39, 44, 54, 0.3);
  --rs-state-hover-bg: #f2faff;
  --rs-color-focus-ring: rgba(52, 152, 255, 0.25);
  --rs-state-focus-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  --rs-state-focus-outline: 3px solid rgba(52, 152, 255, 0.25);
  --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12),
    0 0 10px rgba(0, 0, 0, 0.06);
  --rs-btn-default-bg: #f7f7fa;
  --rs-btn-default-text: #575757;
  --rs-btn-default-hover-bg: #e5e5ea;
  --rs-btn-default-active-bg: #d9d9d9;
  --rs-btn-default-active-text: #272c36;
  --rs-btn-default-disabled-bg: #f7f7fa;
  --rs-btn-default-disabled-text: #c5c6c7;
  --rs-btn-primary-bg: #3498ff;
  --rs-btn-primary-text: #fff;
  --rs-btn-primary-hover-bg: #2589f5;
  --rs-btn-primary-active-bg: #1675e0;
  --rs-btn-subtle-text: #8e8e93;
  --rs-btn-subtle-hover-bg: #f7f7fa;
  --rs-btn-subtle-hover-text: #575757;
  --rs-btn-subtle-active-bg: #e5e5ea;
  --rs-btn-subtle-active-text: #272c36;
  --rs-btn-subtle-disabled-text: #c5c6c7;
  --rs-btn-ghost-border: #1675e0;
  --rs-btn-ghost-text: #1675e0;
  --rs-btn-ghost-hover-border: #0a5dc2;
  --rs-btn-ghost-hover-text: #0a5dc2;
  --rs-btn-ghost-active-border: #004299;
  --rs-btn-ghost-active-text: #004299;
  --rs-btn-link-text: #1675e0;
  --rs-btn-link-hover-text: #0a5dc2;
  --rs-btn-link-active-text: #004299;
  --rs-iconbtn-addon: #f2f2f5;
  --rs-iconbtn-activated-addon: #d9d9d9;
  --rs-iconbtn-pressed-addon: #c5c6c7;
  --rs-iconbtn-primary-addon: #2589f5;
  --rs-iconbtn-primary-activated-addon: #1675e0;
  --rs-iconbtn-primary-pressed-addon: #0a5dc2;
  --rs-divider-border: #e5e5ea;
  --rs-loader-ring: rgba(247, 247, 250, 0.8);
  --rs-loader-rotor: #a6a6a6;
  --rs-loader-backdrop: rgba(255, 255, 255, 0.9);
  --rs-loader-ring-inverse: rgba(247, 247, 250, 0.3);
  --rs-loader-rotor-inverse: #fff;
  --rs-loader-backdrop-inverse: rgba(39, 44, 54, 0.83);
  --rs-message-success-header: var(--rs-text-heading);
  --rs-message-success-text: var(--rs-text-primary);
  --rs-message-success-icon: #4caf50;
  --rs-message-success-bg: #eeffed;
  --rs-message-info-header: var(--rs-text-heading);
  --rs-message-info-text: var(--rs-text-primary);
  --rs-message-info-icon: #2196f3;
  --rs-message-info-bg: #f0f9ff;
  --rs-message-warning-header: var(--rs-text-heading);
  --rs-message-warning-text: var(--rs-text-primary);
  --rs-message-warning-icon: #ffb300;
  --rs-message-warning-bg: #fffaf2;
  --rs-message-error-header: var(--rs-text-heading);
  --rs-message-error-text: var(--rs-text-primary);
  --rs-message-error-icon: #f44336;
  --rs-message-error-bg: #fff2f2;
  --rs-tooltip-bg: #272c36;
  --rs-tooltip-text: #fff;
  --rs-progress-bg: #e5e5ea;
  --rs-progress-bar: #3498ff;
  --rs-progress-bar-success: #4caf50;
  --rs-progress-bar-fail: #f44336;
  --rs-placeholder: #f2f2f5;
  --rs-placeholder-active: #e5e5ea;
  --rs-dropdown-divider: #e5e5ea;
  --rs-dropdown-item-bg-hover: rgba(204, 233, 255, 0.5);
  --rs-dropdown-item-bg-active: #f2faff;
  --rs-dropdown-item-text-active: #1675e0;
  --rs-dropdown-header-text: #a6a6a6;
  --rs-dropdown-shadow: 0 0 10px rgba(0, 0, 0, 0.06),
    0 4px 4px rgba(0, 0, 0, 0.12);
  --rs-menuitem-active-bg: rgba(204, 233, 255, 0.5);
  --rs-menuitem-active-text: #1675e0;
  --rs-steps-border: #8e8e93;
  --rs-steps-state-finish: #3498ff;
  --rs-steps-border-state-finish: #3498ff;
  --rs-steps-state-wait: #8e8e93;
  --rs-steps-state-process: #3498ff;
  --rs-steps-state-error: #f44336;
  --rs-steps-border-state-error: #f44336;
  --rs-steps-icon-state-process: #3498ff;
  --rs-steps-icon-state-error: #f44336;
  --rs-navs-text: #8e8e93;
  --rs-navs-text-hover: #575757;
  --rs-navs-bg-hover: #e5e5ea;
  --rs-navs-text-active: #272c36;
  --rs-navs-bg-active: #e5e5ea;
  --rs-navs-tab-border: #d9d9d9;
  --rs-navs-subtle-border: #f7f7fa;
  --rs-navs-selected: #1675e0;
  --rs-navbar-default-bg: #f7f7fa;
  --rs-navbar-default-text: #575757;
  --rs-navbar-default-selected-text: #1675e0;
  --rs-navbar-default-hover-bg: #e5e5ea;
  --rs-navbar-default-hover-text: #575757;
  --rs-navbar-inverse-bg: #3498ff;
  --rs-navbar-inverse-text: #fff;
  --rs-navbar-inverse-selected-bg: #1675e0;
  --rs-navbar-inverse-hover-bg: #2589f5;
  --rs-navbar-inverse-hover-text: #fff;
  --rs-navbar-subtle-bg: #fff;
  --rs-navbar-subtle-text: #8e8e93;
  --rs-navbar-subtle-selected-text: #1675e0;
  --rs-navbar-subtle-hover-bg: #f7f7fa;
  --rs-navbar-subtle-hover-text: #575757;
  --rs-sidenav-default-bg: #f7f7fa;
  --rs-sidenav-default-text: #575757;
  --rs-sidenav-default-selected-text: #1675e0;
  --rs-sidenav-default-hover-bg: #e5e5ea;
  --rs-sidenav-default-hover-text: #575757;
  --rs-sidenav-default-footer-border: #e5e5ea;
  --rs-sidenav-inverse-bg: #3498ff;
  --rs-sidenav-inverse-text: #fff;
  --rs-sidenav-inverse-selected-bg: #1675e0;
  --rs-sidenav-inverse-hover-bg: #2589f5;
  --rs-sidenav-inverse-footer-border: #2589f5;
  --rs-sidenav-subtle-bg: #fff;
  --rs-sidenav-subtle-text: #8e8e93;
  --rs-sidenav-subtle-selected-text: #1675e0;
  --rs-sidenav-subtle-hover-bg: #f7f7fa;
  --rs-sidenav-subtle-hover-text: #575757;
  --rs-sidenav-subtle-footer-border: #e5e5ea;
  --rs-input-bg: #fff;
  --rs-input-focus-border: #3498ff;
  --rs-input-disabled-bg: #f7f7fa;
  --rs-listbox-option-hover-bg: rgba(204, 233, 255, 0.5);
  --rs-listbox-option-hover-text: #1675e0;
  --rs-listbox-option-selected-text: #1675e0;
  --rs-listbox-option-selected-bg: #f2faff;
  --rs-listbox-option-disabled-text: #c5c6c7;
  --rs-listbox-option-disabled-selected-text: #a6d7ff;
  --rs-checkbox-icon: #fff;
  --rs-checkbox-border: #d9d9d9;
  --rs-checkbox-checked-bg: #3498ff;
  --rs-checkbox-disabled-bg: #f7f7fa;
  --rs-radio-marker: #fff;
  --rs-radio-border: #d9d9d9;
  --rs-radio-checked-bg: #3498ff;
  --rs-radio-disabled-bg: #f7f7fa;
  --rs-rate-symbol: #8e8e93;
  --rs-rate-symbol-checked: #ffb300;
  --rs-toggle-bg: #d9d9d9;
  --rs-toggle-thumb: #fff;
  --rs-toggle-loader-ring: rgba(247, 247, 250, 0.3);
  --rs-toggle-loader-rotor: #fff;
  --rs-toggle-hover-bg: #c5c6c7;
  --rs-toggle-disabled-bg: #f7f7fa;
  --rs-toggle-disabled-thumb: #fff;
  --rs-toggle-checked-bg: #3498ff;
  --rs-toggle-checked-thumb: #fff;
  --rs-toggle-checked-hover-bg: #2589f5;
  --rs-toggle-checked-disabled-bg: #cce9ff;
  --rs-toggle-checked-disabled-thumb: #fff;
  --rs-slider-bar: #f2f2f5;
  --rs-slider-hover-bar: #e5e5ea;
  --rs-slider-thumb-border: #3498ff;
  --rs-slider-thumb-bg: #fff;
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
  --rs-slider-progress: #3498ff;
  --rs-uploader-item-bg: #d9d9d9;
  --rs-uploader-item-hover-bg: #f7f7fa;
  --rs-uploader-overlay-bg: rgba(255, 255, 255, 0.8);
  --rs-uploader-dnd-bg: #fff;
  --rs-uploader-dnd-border: #e5e5ea;
  --rs-uploader-dnd-hover-border: #3498ff;
  --rs-avatar-bg: #d9d9d9;
  --rs-avatar-text: #fff;
  --rs-badge-bg: #f44336;
  --rs-badge-text: #fff;
  --rs-tag-bg: #f7f7fa;
  --rs-tag-close: #f44336;
  --rs-carousel-bg: #8e8e93;
  --rs-carousel-indicator: rgba(255, 255, 255, 0.4);
  --rs-carousel-indicator-hover: #fff;
  --rs-carousel-indicator-active: #3498ff;
  --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-list-bg: #fff;
  --rs-list-border: #e5e5ea;
  --rs-list-hover-bg: #f2faff;
  --rs-list-placeholder-bg: rgba(242, 250, 255, 0.5);
  --rs-list-placeholder-border: #3498ff;
  --rs-timeline-indicator-bg: #d9d9d9;
  --rs-timeline-indicator-active-bg: #3498ff;
  --rs-table-shadow: rgba(9, 9, 9, 0.08);
  --rs-table-sort: #3498ff;
  --rs-table-resize: #3498ff;
  --rs-table-scrollbar-track: #e5e5ea;
  --rs-table-scrollbar-thumb: #575757;
  --rs-table-scrollbar-thumb-active: #272c36;
  --rs-table-scrollbar-vertical-track: rgba(229, 229, 234, 0.4);
  --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, 0.12),
    0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-form-errormessage-text: #f44336;
  --rs-form-errormessage-bg: #fff;
  --rs-form-errormessage-border: #e5e5ea;
  --rs-picker-value: #1675e0;
  --rs-picker-count-bg: #3498ff;
  --rs-picker-count-text: #fff;
  --rs-calendar-today-bg: #3498ff;
  --rs-calendar-today-text: #fff;
  --rs-calendar-range-bg: rgba(204, 233, 255, 0.5);
  --rs-calendar-time-unit-bg: #f7f7fa;
  --rs-calendar-date-selected-text: #fff;
  --rs-popover-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
}
.rs-theme-dark {
  --rs-gray-50: #e9ebf0;
  --rs-gray-100: #cbced4;
  --rs-gray-200: #a4a9b3;
  --rs-gray-300: #858b94;
  --rs-gray-400: #6a6f76;
  --rs-gray-500: #5c6066;
  --rs-gray-600: #3c3f43;
  --rs-gray-700: #292d33;
  --rs-gray-800: #1a1d24;
  --rs-gray-900: #0f131a;
  --rs-primary-50: #f2fcff;
  --rs-primary-100: #ccf3ff;
  --rs-primary-200: #a6e9ff;
  --rs-primary-300: #80ddff;
  --rs-primary-400: #59d0ff;
  --rs-primary-500: #34c3ff;
  --rs-primary-600: #25b3f5;
  --rs-primary-700: #169de0;
  --rs-primary-800: #0a81c2;
  --rs-primary-900: #006199;
  --rs-red-50: #ffeded;
  --rs-red-100: #fccaca;
  --rs-red-200: #faa9a7;
  --rs-red-300: #f58884;
  --rs-red-400: #f26a63;
  --rs-red-500: #f04f43;
  --rs-red-600: #e63f30;
  --rs-red-700: #d12f1d;
  --rs-red-800: #b3200c;
  --rs-red-900: #8a1200;
  --rs-orange-50: #fff9f2;
  --rs-orange-100: #ffe2c2;
  --rs-orange-200: #ffce91;
  --rs-orange-300: #ffba61;
  --rs-orange-400: #ffa930;
  --rs-orange-500: #ff9800;
  --rs-orange-600: #f59700;
  --rs-orange-700: #e08e00;
  --rs-orange-800: #c27e00;
  --rs-orange-900: #996600;
  --rs-yellow-50: #fff9f0;
  --rs-yellow-100: #ffedd1;
  --rs-yellow-200: #ffe2b3;
  --rs-yellow-300: #ffd894;
  --rs-yellow-400: #ffcf75;
  --rs-yellow-500: #ffc757;
  --rs-yellow-600: #f5bb3d;
  --rs-yellow-700: #e0a824;
  --rs-yellow-800: #c28f0e;
  --rs-yellow-900: #997000;
  --rs-green-50: #f3fff2;
  --rs-green-100: #cff0ce;
  --rs-green-200: #aee0ad;
  --rs-green-300: #8ccf8c;
  --rs-green-400: #71bf72;
  --rs-green-500: #58b15b;
  --rs-green-600: #3fab45;
  --rs-green-700: #27a12f;
  --rs-green-800: #10911b;
  --rs-green-900: #007d0c;
  --rs-cyan-50: #f2ffff;
  --rs-cyan-100: #bcf4f7;
  --rs-cyan-200: #87e6ed;
  --rs-cyan-300: #57dae6;
  --rs-cyan-400: #2acadb;
  --rs-cyan-500: #00bcd4;
  --rs-cyan-600: #00b1cc;
  --rs-cyan-700: #00a0bd;
  --rs-cyan-800: #008aa6;
  --rs-cyan-900: #006e87;
  --rs-blue-50: #edf9ff;
  --rs-blue-100: #c0e8fc;
  --rs-blue-200: #93d6fa;
  --rs-blue-300: #67c1f5;
  --rs-blue-400: #3dadf2;
  --rs-blue-500: #1499ef;
  --rs-blue-600: #0e8ce6;
  --rs-blue-700: #087ad1;
  --rs-blue-800: #0464b3;
  --rs-blue-900: #00498a;
  --rs-violet-50: #f6f2ff;
  --rs-violet-100: #d5c9f0;
  --rs-violet-200: #b6a1e3;
  --rs-violet-300: #987bd4;
  --rs-violet-400: #805ac7;
  --rs-violet-500: #673ab7;
  --rs-violet-600: #5f2bb3;
  --rs-violet-700: #531ba8;
  --rs-violet-800: #470c99;
  --rs-violet-900: #390085;
  --rs-state-success: #58b15b;
  --rs-state-info: #1499ef;
  --rs-state-warning: #ffc757;
  --rs-state-error: #f04f43;
  --rs-body: #0f131a;
  --rs-text-link: #34c3ff;
  --rs-text-link-hover: #59d0ff;
  --rs-text-link-active: #80ddff;
  --rs-text-primary: #e9ebf0;
  --rs-text-secondary: #a4a9b3;
  --rs-text-tertiary: #858b94;
  --rs-text-heading: #fff;
  --rs-text-inverse: #1a1d24;
  --rs-text-heading-inverse: #0f131a;
  --rs-text-active: #34c3ff;
  --rs-text-disabled: #5c6066;
  --rs-border-primary: #3c3f43;
  --rs-border-secondary: #292d33;
  --rs-bg-card: #1a1d24;
  --rs-bg-overlay: #292d33;
  --rs-bg-well: #0f131a;
  --rs-bg-active: #169de0;
  --rs-bg-backdrop: rgba(15, 19, 26, 0.8);
  --rs-state-hover-bg: #3c3f43;
  --rs-color-focus-ring: 0 0 0 3px rgba(52, 195, 255, 0.25);
  --rs-state-focus-shadow: 0 0 0 3px rgba(52, 195, 255, 0.25);
  --rs-state-focus-outline: 3px solid rgba(52, 195, 255, 0.25);
  --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12),
    0 0 10px rgba(0, 0, 0, 0.06);
  --rs-btn-default-bg: #3c3f43;
  --rs-btn-default-text: #e9ebf0;
  --rs-btn-default-hover-bg: #5c6066;
  --rs-btn-default-active-bg: #858b94;
  --rs-btn-default-active-text: #fff;
  --rs-btn-default-disabled-bg: #3c3f43;
  --rs-btn-default-disabled-text: #5c6066;
  --rs-btn-primary-bg: #169de0;
  --rs-btn-primary-text: #fff;
  --rs-btn-primary-hover-bg: #25b3f5;
  --rs-btn-primary-active-bg: #59d0ff;
  --rs-btn-subtle-text: #a4a9b3;
  --rs-btn-subtle-hover-bg: #292d33;
  --rs-btn-subtle-hover-text: #e9ebf0;
  --rs-btn-subtle-active-bg: #6a6f76;
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-subtle-disabled-text: #5c6066;
  --rs-btn-ghost-border: #34c3ff;
  --rs-btn-ghost-text: #34c3ff;
  --rs-btn-ghost-hover-border: #59d0ff;
  --rs-btn-ghost-hover-text: #59d0ff;
  --rs-btn-ghost-active-border: #a6e9ff;
  --rs-btn-ghost-active-text: #a6e9ff;
  --rs-btn-link-text: #34c3ff;
  --rs-btn-link-hover-text: #59d0ff;
  --rs-btn-link-active-text: #a6e9ff;
  --rs-iconbtn-addon: #5c6066;
  --rs-iconbtn-activated-addon: #6a6f76;
  --rs-iconbtn-pressed-addon: #a4a9b3;
  --rs-iconbtn-primary-addon: #25b3f5;
  --rs-iconbtn-primary-activated-addon: #34c3ff;
  --rs-iconbtn-primary-pressed-addon: #59d0ff;
  --rs-divider-border: #3c3f43;
  --rs-loader-ring: rgba(233, 235, 240, 0.3);
  --rs-loader-rotor: #fff;
  --rs-loader-backdrop: rgba(15, 19, 26, 0.83);
  --rs-loader-ring-inverse: rgba(233, 235, 240, 0.8);
  --rs-loader-rotor-inverse: #5c6066;
  --rs-loader-backdrop-inverse: rgba(255, 255, 255, 0.9);
  --rs-message-success-header: #fff;
  --rs-message-success-text: #fff;
  --rs-message-success-icon: #fff;
  --rs-message-success-bg: #4caf50;
  --rs-message-info-header: #fff;
  --rs-message-info-text: #fff;
  --rs-message-info-icon: #fff;
  --rs-message-info-bg: #2196f3;
  --rs-message-warning-header: #0f131a;
  --rs-message-warning-text: #0f131a;
  --rs-message-warning-icon: #0f131a;
  --rs-message-warning-bg: #ffb300;
  --rs-message-error-header: #fff;
  --rs-message-error-text: #fff;
  --rs-message-error-icon: #fff;
  --rs-message-error-bg: #f44336;
  --rs-tooltip-bg: #5c6066;
  --rs-tooltip-text: #fff;
  --rs-progress-bg: #292d33;
  --rs-progress-bar: #34c3ff;
  --rs-progress-bar-success: #4caf50;
  --rs-progress-bar-fail: #f44336;
  --rs-placeholder: #3c3f43;
  --rs-placeholder-active: #484c50;
  --rs-dropdown-divider: #3c3f43;
  --rs-dropdown-item-bg-hover: #3c3f43;
  --rs-dropdown-item-bg-active: rgba(0, 97, 153, 0.2);
  --rs-dropdown-item-text-active: #34c3ff;
  --rs-dropdown-header-text: #5c6066;
  --rs-dropdown-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2),
    0 4px 4px 3px rgba(0, 0, 0, 0.24);
  --rs-menuitem-active-bg: #3c3f43;
  --rs-menuitem-active-text: currentColor;
  --rs-steps-border: #a4a9b3;
  --rs-steps-state-finish: #34c3ff;
  --rs-steps-border-state-finish: #34c3ff;
  --rs-steps-state-wait: #a4a9b3;
  --rs-steps-state-process: #169de0;
  --rs-steps-state-error: #f44336;
  --rs-steps-border-state-error: #f44336;
  --rs-steps-icon-state-process: #34c3ff;
  --rs-steps-icon-state-error: #f44336;
  --rs-navs-text: #a4a9b3;
  --rs-navs-text-hover: #cbced4;
  --rs-navs-bg-hover: #6a6f76;
  --rs-navs-text-active: #fff;
  --rs-navs-bg-active: #6a6f76;
  --rs-navs-tab-border: #3c3f43;
  --rs-navs-subtle-border: #3c3f43;
  --rs-navs-selected: #34c3ff;
  --rs-navbar-default-bg: #1a1d24;
  --rs-navbar-default-text: #a4a9b3;
  --rs-navbar-default-selected-text: #34c3ff;
  --rs-navbar-default-hover-bg: #292d33;
  --rs-navbar-default-hover-text: #e9ebf0;
  --rs-navbar-inverse-bg: #169de0;
  --rs-navbar-inverse-text: #fff;
  --rs-navbar-inverse-selected-bg: #59d0ff;
  --rs-navbar-inverse-hover-bg: #25b3f5;
  --rs-navbar-inverse-hover-text: #fff;
  --rs-navbar-subtle-bg: transparent;
  --rs-navbar-subtle-text: #a4a9b3;
  --rs-navbar-subtle-selected-text: #34c3ff;
  --rs-navbar-subtle-hover-bg: #292d33;
  --rs-navbar-subtle-hover-text: #e9ebf0;
  --rs-sidenav-default-bg: #1a1d24;
  --rs-sidenav-default-text: #a4a9b3;
  --rs-sidenav-default-selected-text: #34c3ff;
  --rs-sidenav-default-hover-bg: #292d33;
  --rs-sidenav-default-hover-text: #e9ebf0;
  --rs-sidenav-default-footer-border: #3c3f43;
  --rs-sidenav-inverse-bg: #169de0;
  --rs-sidenav-inverse-text: #fff;
  --rs-sidenav-inverse-selected-bg: #59d0ff;
  --rs-sidenav-inverse-hover-bg: #25b3f5;
  --rs-sidenav-inverse-footer-border: #25b3f5;
  --rs-sidenav-subtle-bg: transparent;
  --rs-sidenav-subtle-text: #a4a9b3;
  --rs-sidenav-subtle-selected-text: #34c3ff;
  --rs-sidenav-subtle-hover-bg: #292d33;
  --rs-sidenav-subtle-hover-text: #e9ebf0;
  --rs-sidenav-subtle-footer-border: #3c3f43;
  --rs-input-bg: #1a1d24;
  --rs-input-focus-border: #34c3ff;
  --rs-input-disabled-bg: #292d33;
  --rs-listbox-option-hover-bg: #3c3f43;
  --rs-listbox-option-hover-text: currentColor;
  --rs-listbox-option-selected-text: #34c3ff;
  --rs-listbox-option-selected-bg: rgba(0, 97, 153, 0.2);
  --rs-listbox-option-disabled-text: #5c6066;
  --rs-listbox-option-disabled-selected-text: #a6e9ff;
  --rs-checkbox-icon: #1a1d24;
  --rs-checkbox-border: #6a6f76;
  --rs-checkbox-checked-bg: #34c3ff;
  --rs-checkbox-disabled-bg: #5c6066;
  --rs-radio-marker: #1a1d24;
  --rs-radio-border: #6a6f76;
  --rs-radio-checked-bg: #34c3ff;
  --rs-radio-disabled-bg: #5c6066;
  --rs-rate-symbol: #3c3f43;
  --rs-rate-symbol-checked: #ffb300;
  --rs-toggle-bg: #6a6f76;
  --rs-toggle-thumb: #fff;
  --rs-toggle-hover-bg: #858b94;
  --rs-toggle-disabled-bg: #3c3f43;
  --rs-toggle-disabled-thumb: #5c6066;
  --rs-toggle-checked-bg: #169de0;
  --rs-toggle-checked-thumb: #fff;
  --rs-toggle-checked-hover-bg: #25b3f5;
  --rs-toggle-checked-disabled-bg: #006199;
  --rs-toggle-checked-disabled-thumb: #858b94;
  --rs-slider-bar: #3c3f43;
  --rs-slider-hover-bar: #3c3f43;
  --rs-slider-thumb-border: #34c3ff;
  --rs-slider-thumb-bg: #292d33;
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(52, 195, 255, 0.25);
  --rs-slider-progress: #34c3ff;
  --rs-uploader-item-bg: #858b94;
  --rs-uploader-item-hover-bg: #3c3f43;
  --rs-uploader-overlay-bg: rgba(60, 63, 67, 0.8);
  --rs-uploader-dnd-bg: #292d33;
  --rs-uploader-dnd-border: #a4a9b3;
  --rs-uploader-dnd-hover-border: #34c3ff;
  --rs-avatar-bg: #6a6f76;
  --rs-avatar-text: #fff;
  --rs-badge-bg: #f44336;
  --rs-badge-text: #fff;
  --rs-tag-bg: #3c3f43;
  --rs-tag-close: #f44336;
  --rs-carousel-bg: #3c3f43;
  --rs-carousel-indicator: rgba(255, 255, 255, 0.4);
  --rs-carousel-indicator-hover: #fff;
  --rs-carousel-indicator-active: #34c3ff;
  --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-list-bg: #0f131a;
  --rs-list-border: #292d33;
  --rs-list-hover-bg: #3c3f43;
  --rs-list-placeholder-bg: rgba(0, 97, 153, 0.2);
  --rs-list-placeholder-border: #34c3ff;
  --rs-timeline-indicator-bg: #5c6066;
  --rs-timeline-indicator-active-bg: #34c3ff;
  --rs-table-shadow: rgba(9, 9, 9, 0.99);
  --rs-table-sort: #34c3ff;
  --rs-table-resize: #34c3ff;
  --rs-table-scrollbar-track: #292d33;
  --rs-table-scrollbar-thumb: #a4a9b3;
  --rs-table-scrollbar-thumb-active: #cbced4;
  --rs-table-scrollbar-vertical-track: #292d33;
  --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, 0.12),
    0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-form-errormessage-text: #fff;
  --rs-form-errormessage-bg: #f44336;
  --rs-form-errormessage-border: #f44336;
  --rs-picker-value: #34c3ff;
  --rs-picker-count-bg: #169de0;
  --rs-picker-count-text: #fff;
  --rs-calendar-today-bg: #169de0;
  --rs-calendar-today-text: #fff;
  --rs-calendar-range-bg: rgba(0, 97, 153, 0.5);
  --rs-calendar-time-unit-bg: #3c3f43;
  --rs-calendar-date-selected-text: #fff;
  --rs-popover-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}
.rs-theme-high-contrast {
  --rs-gray-50: #e9ebf0;
  --rs-gray-100: #cbced4;
  --rs-gray-200: #a4a9b3;
  --rs-gray-300: #858b94;
  --rs-gray-400: #6a6f76;
  --rs-gray-500: #5c6066;
  --rs-gray-600: #3c3f43;
  --rs-gray-700: #292d33;
  --rs-gray-800: #1a1d24;
  --rs-gray-900: #0f131a;
  --rs-primary-50: #fffef2;
  --rs-primary-100: #fffbc2;
  --rs-primary-200: #fffa91;
  --rs-primary-300: #fffa61;
  --rs-primary-400: #fffc30;
  --rs-primary-500: #ffff00;
  --rs-primary-600: #f1f500;
  --rs-primary-700: #d9e000;
  --rs-primary-800: #b8c200;
  --rs-primary-900: #8f9900;
  --rs-red-50: #fff2f5;
  --rs-red-100: #f2c2cd;
  --rs-red-200: #e691a3;
  --rs-red-300: #d6637a;
  --rs-red-400: #c93a55;
  --rs-red-500: #bd1732;
  --rs-red-600: #b51029;
  --rs-red-700: #a60a1f;
  --rs-red-800: #8f0414;
  --rs-red-900: #70000b;
  --rs-orange-50: #fff9f2;
  --rs-orange-100: #ffe2c2;
  --rs-orange-200: #ffce91;
  --rs-orange-300: #ffba61;
  --rs-orange-400: #ffa930;
  --rs-orange-500: #ff9800;
  --rs-orange-600: #f59700;
  --rs-orange-700: #e08e00;
  --rs-orange-800: #c27e00;
  --rs-orange-900: #996600;
  --rs-yellow-50: #fff9f0;
  --rs-yellow-100: #ffedd1;
  --rs-yellow-200: #ffe2b3;
  --rs-yellow-300: #ffd894;
  --rs-yellow-400: #ffcf75;
  --rs-yellow-500: #ffc757;
  --rs-yellow-600: #f5bb3d;
  --rs-yellow-700: #e0a824;
  --rs-yellow-800: #c28f0e;
  --rs-yellow-900: #997000;
  --rs-green-50: #f2fff5;
  --rs-green-100: #b3e6bd;
  --rs-green-200: #7ccc8e;
  --rs-green-300: #50b567;
  --rs-green-400: #2a9c46;
  --rs-green-500: #0d822c;
  --rs-green-600: #09802b;
  --rs-green-700: #057a28;
  --rs-green-800: #017325;
  --rs-green-900: #006923;
  --rs-cyan-50: #f2ffff;
  --rs-cyan-100: #bcf4f7;
  --rs-cyan-200: #87e6ed;
  --rs-cyan-300: #57dae6;
  --rs-cyan-400: #2acadb;
  --rs-cyan-500: #00bcd4;
  --rs-cyan-600: #00b1cc;
  --rs-cyan-700: #00a0bd;
  --rs-cyan-800: #008aa6;
  --rs-cyan-900: #006e87;
  --rs-blue-50: #edf9ff;
  --rs-blue-100: #c0e8fc;
  --rs-blue-200: #93d6fa;
  --rs-blue-300: #67c1f5;
  --rs-blue-400: #3dadf2;
  --rs-blue-500: #1499ef;
  --rs-blue-600: #0e8ce6;
  --rs-blue-700: #087ad1;
  --rs-blue-800: #0464b3;
  --rs-blue-900: #00498a;
  --rs-violet-50: #f6f2ff;
  --rs-violet-100: #d5c9f0;
  --rs-violet-200: #b6a1e3;
  --rs-violet-300: #987bd4;
  --rs-violet-400: #805ac7;
  --rs-violet-500: #673ab7;
  --rs-violet-600: #5f2bb3;
  --rs-violet-700: #531ba8;
  --rs-violet-800: #470c99;
  --rs-violet-900: #390085;
  --rs-state-success: #0d822c;
  --rs-state-info: #1499ef;
  --rs-state-warning: #ffc757;
  --rs-state-error: #bd1732;
  --rs-body: #0f131a;
  --rs-text-link: #ffff00;
  --rs-text-link-hover: #fffc30;
  --rs-text-link-active: #fffa61;
  --rs-text-primary: #e9ebf0;
  --rs-text-secondary: #a4a9b3;
  --rs-text-tertiary: #858b94;
  --rs-text-heading: #fff;
  --rs-text-inverse: #1a1d24;
  --rs-text-heading-inverse: #0f131a;
  --rs-text-active: #ffff00;
  --rs-text-disabled: #5c6066;
  --rs-border-primary: #cbced4;
  --rs-border-secondary: #292d33;
  --rs-bg-card: #1a1d24;
  --rs-bg-overlay: #1a1d24;
  --rs-bg-well: #0f131a;
  --rs-bg-active: #ffff00;
  --rs-bg-backdrop: rgba(15, 19, 26, 0.8);
  --rs-state-hover-bg: #3c3f43;
  --rs-color-focus-ring: #fff;
  --rs-state-focus-shadow: 0 0 0 3px #0f131a, 0 0 0 5px #fff;
  --rs-state-focus-shadow-slim: 0 0 0 2px #fff;
  --rs-state-focus-outline: 3px solid rgba(255, 255, 0, 0.25);
  --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12),
    0 0 10px rgba(0, 0, 0, 0.06);
  --rs-btn-default-bg: transparent;
  --rs-btn-default-text: #ffff00;
  --rs-btn-default-border: 1px solid #ffff00;
  --rs-btn-default-hover-bg: transparent;
  --rs-btn-default-hover-text: #fffc30;
  --rs-btn-default-active-bg: transparent;
  --rs-btn-default-active-text: #fffa91;
  --rs-btn-default-disabled-bg: transparent;
  --rs-btn-default-disabled-text: #ffff00;
  --rs-btn-primary-bg: #ffff00;
  --rs-btn-primary-text: #0f131a;
  --rs-btn-primary-hover-bg: #fffc30;
  --rs-btn-primary-active-bg: #fffa91;
  --rs-btn-subtle-text: #ffff00;
  --rs-btn-subtle-hover-bg: transparent;
  --rs-btn-subtle-hover-text: #fffc30;
  --rs-btn-subtle-active-bg: transparent;
  --rs-btn-subtle-active-text: #fffa91;
  --rs-btn-subtle-disabled-text: #5c6066;
  --rs-btn-ghost-border: #ffff00;
  --rs-btn-ghost-text: #ffff00;
  --rs-btn-ghost-hover-border: #fffc30;
  --rs-btn-ghost-hover-text: #fffc30;
  --rs-btn-ghost-active-border: #fffa91;
  --rs-btn-ghost-active-text: #fffa91;
  --rs-btn-link-text: #ffff00;
  --rs-btn-link-hover-text: #fffc30;
  --rs-btn-link-active-text: #fffa91;
  --rs-iconbtn-addon: transparent;
  --rs-iconbtn-activated-addon: transparent;
  --rs-iconbtn-pressed-addon: transparent;
  --rs-iconbtn-primary-addon: #fffc30;
  --rs-iconbtn-primary-activated-addon: #fffa61;
  --rs-iconbtn-primary-pressed-addon: #fffbc2;
  --rs-divider-border: #3c3f43;
  --rs-loader-ring: rgba(233, 235, 240, 0.3);
  --rs-loader-rotor: #fff;
  --rs-loader-backdrop: rgba(15, 19, 26, 0.83);
  --rs-loader-ring-inverse: rgba(233, 235, 240, 0.8);
  --rs-loader-rotor-inverse: #5c6066;
  --rs-loader-backdrop-inverse: rgba(255, 255, 255, 0.9);
  --rs-message-success-header: #fff;
  --rs-message-success-text: #fff;
  --rs-message-success-icon: #fff;
  --rs-message-success-bg: #007d0c;
  --rs-message-success-border: #82cf82;
  --rs-message-info-header: #fff;
  --rs-message-info-text: #fff;
  --rs-message-info-icon: #fff;
  --rs-message-info-bg: #00448c;
  --rs-message-info-border: #2196f3;
  --rs-message-warning-header: #fff;
  --rs-message-warning-text: #fff;
  --rs-message-warning-icon: #fff;
  --rs-message-warning-bg: #997500;
  --rs-message-warning-border: #ffb300;
  --rs-message-error-header: #fff;
  --rs-message-error-text: #fff;
  --rs-message-error-icon: #fff;
  --rs-message-error-bg: #8f1300;
  --rs-message-error-border: #fa8682;
  --rs-tooltip-bg: #1a1d24;
  --rs-tooltip-text: #fff;
  --rs-progress-bg: #292d33;
  --rs-progress-bar: #ffff00;
  --rs-progress-bar-success: #4caf50;
  --rs-progress-bar-fail: #f44336;
  --rs-placeholder: #3c3f43;
  --rs-placeholder-active: #484c50;
  --rs-dropdown-divider: #3c3f43;
  --rs-dropdown-item-bg-hover: #3c3f43;
  --rs-dropdown-item-bg-active: rgba(143, 153, 0, 0.2);
  --rs-dropdown-item-text-active: #ffff00;
  --rs-dropdown-header-text: #5c6066;
  --rs-dropdown-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2),
    0 4px 4px 3px rgba(0, 0, 0, 0.24);
  --rs-menuitem-active-bg: transparent;
  --rs-menuitem-active-text: #ffff00;
  --rs-steps-border: #a4a9b3;
  --rs-steps-state-finish: #ffff00;
  --rs-steps-border-state-finish: #ffff00;
  --rs-steps-state-wait: #a4a9b3;
  --rs-steps-state-process: #d9e000;
  --rs-steps-state-error: #f44336;
  --rs-steps-border-state-error: #f44336;
  --rs-steps-icon-state-process: #ffff00;
  --rs-steps-icon-state-error: #f44336;
  --rs-navs-text: #a4a9b3;
  --rs-navs-text-hover: #ffff00;
  --rs-navs-bg-hover: transparent;
  --rs-navs-text-active: #ffff00;
  --rs-navs-bg-active: #6a6f76;
  --rs-navs-tab-border: #3c3f43;
  --rs-navs-subtle-border: #3c3f43;
  --rs-navs-selected: #ffff00;
  --rs-navbar-default-bg: #1a1d24;
  --rs-navbar-default-text: #e9ebf0;
  --rs-navbar-default-selected-text: #ffff00;
  --rs-navbar-default-hover-bg: transparent;
  --rs-navbar-default-hover-text: #ffff00;
  --rs-navbar-inverse-bg: #1a1d24;
  --rs-navbar-inverse-text: #e9ebf0;
  --rs-navbar-inverse-selected-bg: transparent;
  --rs-navbar-inverse-selected-text: #ffff00;
  --rs-navbar-inverse-hover-bg: transparent;
  --rs-navbar-inverse-hover-text: #ffff00;
  --rs-navbar-subtle-bg: #1a1d24;
  --rs-navbar-subtle-text: #e9ebf0;
  --rs-navbar-subtle-selected-text: #ffff00;
  --rs-navbar-subtle-hover-bg: transparent;
  --rs-navbar-subtle-hover-text: #ffff00;
  --rs-sidenav-default-bg: #1a1d24;
  --rs-sidenav-default-text: #e9ebf0;
  --rs-sidenav-default-selected-text: #ffff00;
  --rs-sidenav-default-hover-bg: transparent;
  --rs-sidenav-default-hover-text: #ffff00;
  --rs-sidenav-default-footer-border: #e9ebf0;
  --rs-sidenav-inverse-bg: #1a1d24;
  --rs-sidenav-inverse-text: #e9ebf0;
  --rs-sidenav-inverse-selected-bg: transparent;
  --rs-sidenav-inverse-selected-text: #ffff00;
  --rs-sidenav-inverse-hover-bg: transparent;
  --rs-sidenav-inverse-footer-border: #e9ebf0;
  --rs-sidenav-subtle-bg: #1a1d24;
  --rs-sidenav-subtle-text: #e9ebf0;
  --rs-sidenav-subtle-selected-text: #ffff00;
  --rs-sidenav-subtle-hover-bg: transparent;
  --rs-sidenav-subtle-hover-text: #ffff00;
  --rs-sidenav-subtle-footer-border: #e9ebf0;
  --rs-input-bg: #1a1d24;
  --rs-input-focus-border: #ffff00;
  --rs-input-disabled-bg: #292d33;
  --rs-listbox-option-hover-bg: transparent;
  --rs-listbox-option-hover-text: #ffff00;
  --rs-listbox-option-selected-text: #ffff00;
  --rs-listbox-option-selected-bg: transparent;
  --rs-listbox-option-disabled-text: #5c6066;
  --rs-listbox-option-disabled-selected-text: #fffa91;
  --rs-checkbox-icon: #1a1d24;
  --rs-checkbox-border: #cbced4;
  --rs-checkbox-checked-bg: #ffff00;
  --rs-checkbox-disabled-bg: #5c6066;
  --rs-radio-marker: #1a1d24;
  --rs-radio-border: #cbced4;
  --rs-radio-checked-bg: #ffff00;
  --rs-radio-disabled-bg: #5c6066;
  --rs-rate-symbol: #cbced4;
  --rs-rate-symbol-checked: #ffff00;
  --rs-toggle-bg: #1a1d24;
  --rs-toggle-thumb: #cbced4;
  --rs-toggle-hover-bg: #1a1d24;
  --rs-toggle-disabled-bg: #1a1d24;
  --rs-toggle-disabled-thumb: #858b94;
  --rs-toggle-checked-bg: #ffff00;
  --rs-toggle-checked-thumb: #1a1d24;
  --rs-toggle-checked-hover-bg: #fffc30;
  --rs-toggle-checked-disabled-bg: #8f9900;
  --rs-toggle-checked-disabled-thumb: #1a1d24;
  --rs-slider-bar: #3c3f43;
  --rs-slider-hover-bar: #3c3f43;
  --rs-slider-thumb-border: #ffff00;
  --rs-slider-thumb-bg: #292d33;
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(255, 255, 0, 0.25);
  --rs-slider-progress: #ffff00;
  --rs-uploader-item-bg: #858b94;
  --rs-uploader-item-hover-bg: #1a1d24;
  --rs-uploader-item-hover-text: #ffff00;
  --rs-uploader-overlay-bg: rgba(60, 63, 67, 0.8);
  --rs-uploader-dnd-bg: #292d33;
  --rs-uploader-dnd-border: #a4a9b3;
  --rs-uploader-dnd-hover-border: #ffff00;
  --rs-avatar-bg: #6a6f76;
  --rs-avatar-text: #fff;
  --rs-badge-bg: #f44336;
  --rs-badge-text: #fff;
  --rs-tag-bg: #3c3f43;
  --rs-tag-close: #f44336;
  --rs-carousel-bg: #3c3f43;
  --rs-carousel-indicator: rgba(255, 255, 255, 0.4);
  --rs-carousel-indicator-hover: #fff;
  --rs-carousel-indicator-active: #ffff00;
  --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-pagination-item-text: #e9ebf0;
  --rs-pagination-item-current-text: #ffff00;
  --rs-list-bg: transparent;
  --rs-list-border: #292d33;
  --rs-list-hover-bg: #3c3f43;
  --rs-list-placeholder-bg: rgba(143, 153, 0, 0.2);
  --rs-list-placeholder-border: #ffff00;
  --rs-timeline-indicator-bg: #5c6066;
  --rs-timeline-indicator-active-bg: #ffff00;
  --rs-table-shadow: rgba(9, 9, 9, 0.99);
  --rs-table-sort: #ffff00;
  --rs-table-resize: #ffff00;
  --rs-table-scrollbar-track: #292d33;
  --rs-table-scrollbar-thumb: #a4a9b3;
  --rs-table-scrollbar-thumb-active: #cbced4;
  --rs-table-scrollbar-vertical-track: #292d33;
  --rs-drawer-bg: #292d33;
  --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, 0.12),
    0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-border: 1px solid #cbced4;
  --rs-form-errormessage-text: #fff;
  --rs-form-errormessage-bg: #f44336;
  --rs-form-errormessage-border: #f44336;
  --rs-picker-value: #ffff00;
  --rs-picker-count-bg: #ffff00;
  --rs-picker-count-text: #0f131a;
  --rs-calendar-today-bg: #ffff00;
  --rs-calendar-today-text: #0f131a;
  --rs-calendar-range-bg: rgba(143, 153, 0, 0.5);
  --rs-calendar-time-unit-bg: #0f131a;
  --rs-calendar-date-selected-text: #0f131a;
  --rs-popover-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}
/* stylelint-disable
* {
  box-sizing: border-box;
}
*::before,
*::after {
  box-sizing: border-box;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
b,
strong {
  font-weight: bold;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
hr {
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button {
  border-radius: 0;
}
button,
select {
  text-transform: none;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: not-allowed;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}
textarea {
  overflow: auto;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
[tabindex='-1'] {
  outline: none;
}
input[type='button'],
input[type='submit'],
input[type='reset'],
button {
  border-width: 0;
}
input::-ms-clear {
  display: none;
}
input[type='file']::-webkit-file-upload-button,
input[type='file']::-ms-browse {
  border-width: 0;
  background: transparent;
  color: currentColor;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  font-family: Apple-System, Arial, Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: var(--rs-text-primary);
  background-color: var(--rs-body);
}
a {
  color: var(--rs-text-link);
  text-decoration: none;
}
a:hover,
a:focus {
  color: var(--rs-text-link-hover);
  text-decoration: underline;
}
a:active {
  color: var(--rs-text-link-active);
  text-decoration: underline;
}
img {
  vertical-align: middle;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid var(--rs-border-primary);
}
[role='button'] {
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: bolder;
  color: inherit;
  margin: 0;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: var(--rs-text-disabled);
}
h1 {
  font-size: 46px;
  line-height: 62px;
}
h2 {
  font-size: 36px;
  line-height: 50px;
}
h3 {
  font-size: 28px;
  line-height: 42px;
}
h4 {
  font-size: 22px;
  line-height: 34px;
}
h5 {
  font-size: 18px;
  line-height: 24px;
}
h6 {
  font-size: 16px;
  line-height: 22px;
}
p {
  margin: 0;
}
p + p {
  margin-top: 8px;
}
small {
  font-size: 85%;
}
mark {
  background-color: var(--rs-bg-warning);
  padding: 0.2em;
  font-weight: bolder;
}
ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
}
ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}
dl {
  margin-top: 0;
  margin-bottom: 10px;
}
dt,
dd {
  line-height: 1.42857143;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 0;
  margin-bottom: 10px;
} */
.rs-btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  transition: color 0.2s linear, background-color 0.3s linear;
  border: var(--rs-btn-default-border, none);
  user-select: none;
  text-decoration: none;
  color: var(--rs-btn-default-text);
  background-color: var(--rs-btn-default-bg);
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  overflow: hidden;
  position: relative;
}
.rs-theme-high-contrast .rs-btn {
  transition: none;
}
.rs-btn-ghost.rs-btn {
  padding: 7px 11px;
}
.rs-btn-icon.rs-btn {
  padding: 10px 10px;
  line-height: 16px;
}
.rs-btn-icon.rs-btn > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-btn {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-btn > .rs-icon {
  padding: 10px 10px;
  width: 36px;
  height: 36px;
}
.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-left {
  padding: 8px 12px 8px 48px;
}
.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-right {
  padding: 8px 48px 8px 12px;
}
.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: var(--rs-btn-default-border, none);
}
.rs-btn:focus-visible {
  outline: 3px solid var(--rs-color-focus-ring);
}
.rs-theme-high-contrast .rs-btn:focus-visible {
  outline-offset: 2px;
}
.rs-btn:hover,
.rs-btn:focus {
  color: var(--rs-btn-default-hover-text);
  background-color: var(--rs-btn-default-hover-bg);
  text-decoration: none;
}
.rs-btn:active,
.rs-btn.rs-btn-active {
  color: var(--rs-btn-default-active-text);
  background-color: var(--rs-btn-default-active-bg);
}
.rs-btn:disabled,
.rs-btn.rs-btn-disabled {
  cursor: not-allowed;
  color: var(--rs-btn-default-disabled-text);
  background-color: var(--rs-btn-default-disabled-bg);
}
.rs-theme-high-contrast .rs-btn:disabled,
.rs-theme-high-contrast .rs-btn.rs-btn-disabled {
  opacity: 0.5;
}
@media not all and (min-resolution: 0.001dpcm) {
  .rs-btn {
    /* stylelint-disable */
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    /* stylelint-enable */
  }
}
.rs-btn-primary {
  color: var(--rs-btn-primary-text);
  background-color: var(--rs-btn-primary-bg);
  border: none;
}
.rs-btn-primary:hover,
.rs-btn-primary:focus {
  color: var(--rs-btn-primary-text);
  background-color: var(--rs-btn-primary-hover-bg);
}
.rs-btn-primary:active,
.rs-btn-primary.rs-btn-active {
  color: var(--rs-btn-primary-text);
  background-color: var(--rs-btn-primary-active-bg);
}
.rs-btn-primary:disabled,
.rs-btn-primary.rs-btn-disabled {
  color: var(--rs-btn-primary-text);
  background-color: var(--rs-btn-primary-bg);
  opacity: 0.3;
}
.rs-theme-high-contrast .rs-btn-primary:disabled,
.rs-theme-high-contrast .rs-btn-primary.rs-btn-disabled {
  opacity: 0.5;
}
.rs-btn-subtle {
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
}
.rs-btn-subtle:hover,
.rs-btn-subtle:focus {
  color: var(--rs-btn-subtle-hover-text);
  background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-btn-subtle:active,
.rs-btn-subtle.rs-btn-active {
  color: var(--rs-btn-subtle-active-text);
  background-color: var(--rs-btn-subtle-active-bg);
}
.rs-btn-subtle:disabled,
.rs-btn-subtle.rs-btn-disabled {
  color: var(--rs-btn-subtle-disabled-text);
  background: none;
}
.rs-theme-high-contrast .rs-btn-subtle:disabled,
.rs-theme-high-contrast .rs-btn-subtle.rs-btn-disabled {
  opacity: 0.5;
}
.rs-btn-link {
  color: var(--rs-btn-link-text);
  background-color: transparent;
  border: none;
}
.rs-btn-link:hover,
.rs-btn-link:focus {
  color: var(--rs-btn-link-hover-text);
  background-color: transparent;
  text-decoration: underline;
}
.rs-btn-link:active,
.rs-btn-link.rs-btn-active {
  color: var(--rs-btn-link-active-text);
  background-color: transparent;
}
.rs-btn-link:disabled,
.rs-btn-link.rs-btn-disabled {
  color: var(--rs-btn-link-hover-text);
  background-color: transparent;
  text-decoration: none;
  opacity: 0.3;
}
.rs-theme-high-contrast .rs-btn-link:disabled,
.rs-theme-high-contrast .rs-btn-link.rs-btn-disabled {
  opacity: 0.5;
}
.rs-btn-ghost {
  color: var(--rs-btn-ghost-text);
  background-color: transparent;
  border: 1px solid var(--rs-btn-ghost-border);
}
.rs-btn-ghost:hover,
.rs-btn-ghost:focus {
  color: var(--rs-btn-ghost-hover-text);
  background-color: transparent;
  border-color: var(--rs-btn-ghost-hover-border);
}
.rs-btn-ghost:active,
.rs-btn-ghost.rs-btn-active {
  color: var(--rs-btn-ghost-active-text);
  background-color: transparent;
  border-color: var(--rs-btn-ghost-active-border);
}
.rs-btn-ghost:disabled,
.rs-btn-ghost.rs-btn-disabled {
  color: var(--rs-btn-ghost-text);
  background-color: transparent;
  opacity: 0.3;
}
.rs-theme-high-contrast .rs-btn-ghost:disabled,
.rs-theme-high-contrast .rs-btn-ghost.rs-btn-disabled {
  opacity: 0.5;
}
.rs-btn-red {
  --rs-btn-primary-bg: var(--rs-red-500);
  --rs-btn-primary-hover-bg: var(--rs-red-600);
  --rs-btn-primary-active-bg: var(--rs-red-700);
  --rs-btn-subtle-hover-bg: var(--rs-red-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-red-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-red-700);
  --rs-btn-ghost-text: var(--rs-red-700);
  --rs-btn-ghost-hover-border: var(--rs-red-800);
  --rs-btn-ghost-hover-text: var(--rs-red-800);
  --rs-btn-ghost-active-border: var(--rs-red-900);
  --rs-btn-ghost-active-text: var(--rs-red-900);
  --rs-btn-link-text: var(--rs-red-700);
  --rs-btn-link-hover-text: var(--rs-red-800);
  --rs-btn-link-active-text: var(--rs-red-900);
  --rs-iconbtn-primary-addon: var(--rs-red-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-red-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-red-800);
}
.rs-theme-dark .rs-btn-red {
  --rs-btn-primary-bg: var(--rs-red-700);
  --rs-btn-primary-hover-bg: var(--rs-red-600);
  --rs-btn-primary-active-bg: var(--rs-red-400);
  --rs-btn-subtle-hover-bg: var(--rs-red-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-red-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-red-500);
  --rs-btn-ghost-text: var(--rs-red-500);
  --rs-btn-ghost-hover-border: var(--rs-red-400);
  --rs-btn-ghost-hover-text: var(--rs-red-400);
  --rs-btn-ghost-active-border: var(--rs-red-200);
  --rs-btn-ghost-active-text: var(--rs-red-200);
  --rs-btn-link-text: var(--rs-red-500);
  --rs-btn-link-hover-text: var(--rs-red-400);
  --rs-btn-link-active-text: var(--rs-red-200);
  --rs-iconbtn-primary-addon: var(--rs-red-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-red-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-red-400);
}
.rs-theme-high-contrast .rs-btn-red {
  --rs-btn-primary-bg: var(--rs-red-700);
  --rs-btn-primary-hover-bg: var(--rs-red-600);
  --rs-btn-primary-active-bg: var(--rs-red-400);
  --rs-btn-subtle-hover-bg: var(--rs-red-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-red-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-red-500);
  --rs-btn-ghost-text: var(--rs-red-500);
  --rs-btn-ghost-hover-border: var(--rs-red-400);
  --rs-btn-ghost-hover-text: var(--rs-red-400);
  --rs-btn-ghost-active-border: var(--rs-red-200);
  --rs-btn-ghost-active-text: var(--rs-red-200);
  --rs-btn-link-text: var(--rs-red-500);
  --rs-btn-link-hover-text: var(--rs-red-400);
  --rs-btn-link-active-text: var(--rs-red-200);
}
.rs-btn-orange {
  --rs-btn-primary-bg: var(--rs-orange-500);
  --rs-btn-primary-hover-bg: var(--rs-orange-600);
  --rs-btn-primary-active-bg: var(--rs-orange-700);
  --rs-btn-subtle-hover-bg: var(--rs-orange-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-orange-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-orange-700);
  --rs-btn-ghost-text: var(--rs-orange-700);
  --rs-btn-ghost-hover-border: var(--rs-orange-800);
  --rs-btn-ghost-hover-text: var(--rs-orange-800);
  --rs-btn-ghost-active-border: var(--rs-orange-900);
  --rs-btn-ghost-active-text: var(--rs-orange-900);
  --rs-btn-link-text: var(--rs-orange-700);
  --rs-btn-link-hover-text: var(--rs-orange-800);
  --rs-btn-link-active-text: var(--rs-orange-900);
  --rs-iconbtn-primary-addon: var(--rs-orange-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-orange-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-orange-800);
}
.rs-theme-dark .rs-btn-orange {
  --rs-btn-primary-bg: var(--rs-orange-700);
  --rs-btn-primary-hover-bg: var(--rs-orange-600);
  --rs-btn-primary-active-bg: var(--rs-orange-400);
  --rs-btn-subtle-hover-bg: var(--rs-orange-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-orange-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-orange-500);
  --rs-btn-ghost-text: var(--rs-orange-500);
  --rs-btn-ghost-hover-border: var(--rs-orange-400);
  --rs-btn-ghost-hover-text: var(--rs-orange-400);
  --rs-btn-ghost-active-border: var(--rs-orange-200);
  --rs-btn-ghost-active-text: var(--rs-orange-200);
  --rs-btn-link-text: var(--rs-orange-500);
  --rs-btn-link-hover-text: var(--rs-orange-400);
  --rs-btn-link-active-text: var(--rs-orange-200);
  --rs-iconbtn-primary-addon: var(--rs-orange-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-orange-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-orange-400);
}
.rs-theme-high-contrast .rs-btn-orange {
  --rs-btn-primary-bg: var(--rs-orange-700);
  --rs-btn-primary-hover-bg: var(--rs-orange-600);
  --rs-btn-primary-active-bg: var(--rs-orange-400);
  --rs-btn-subtle-hover-bg: var(--rs-orange-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-orange-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-orange-500);
  --rs-btn-ghost-text: var(--rs-orange-500);
  --rs-btn-ghost-hover-border: var(--rs-orange-400);
  --rs-btn-ghost-hover-text: var(--rs-orange-400);
  --rs-btn-ghost-active-border: var(--rs-orange-200);
  --rs-btn-ghost-active-text: var(--rs-orange-200);
  --rs-btn-link-text: var(--rs-orange-500);
  --rs-btn-link-hover-text: var(--rs-orange-400);
  --rs-btn-link-active-text: var(--rs-orange-200);
}
.rs-btn-yellow {
  --rs-btn-primary-bg: var(--rs-yellow-500);
  --rs-btn-primary-hover-bg: var(--rs-yellow-600);
  --rs-btn-primary-active-bg: var(--rs-yellow-700);
  --rs-btn-subtle-hover-bg: var(--rs-yellow-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-yellow-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-yellow-700);
  --rs-btn-ghost-text: var(--rs-yellow-700);
  --rs-btn-ghost-hover-border: var(--rs-yellow-800);
  --rs-btn-ghost-hover-text: var(--rs-yellow-800);
  --rs-btn-ghost-active-border: var(--rs-yellow-900);
  --rs-btn-ghost-active-text: var(--rs-yellow-900);
  --rs-btn-link-text: var(--rs-yellow-700);
  --rs-btn-link-hover-text: var(--rs-yellow-800);
  --rs-btn-link-active-text: var(--rs-yellow-900);
  --rs-iconbtn-primary-addon: var(--rs-yellow-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-yellow-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-yellow-800);
}
.rs-theme-dark .rs-btn-yellow {
  --rs-btn-primary-bg: var(--rs-yellow-700);
  --rs-btn-primary-hover-bg: var(--rs-yellow-600);
  --rs-btn-primary-active-bg: var(--rs-yellow-400);
  --rs-btn-subtle-hover-bg: var(--rs-yellow-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-yellow-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-yellow-500);
  --rs-btn-ghost-text: var(--rs-yellow-500);
  --rs-btn-ghost-hover-border: var(--rs-yellow-400);
  --rs-btn-ghost-hover-text: var(--rs-yellow-400);
  --rs-btn-ghost-active-border: var(--rs-yellow-200);
  --rs-btn-ghost-active-text: var(--rs-yellow-200);
  --rs-btn-link-text: var(--rs-yellow-500);
  --rs-btn-link-hover-text: var(--rs-yellow-400);
  --rs-btn-link-active-text: var(--rs-yellow-200);
  --rs-iconbtn-primary-addon: var(--rs-yellow-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-yellow-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-yellow-400);
}
.rs-theme-high-contrast .rs-btn-yellow {
  --rs-btn-primary-bg: var(--rs-yellow-700);
  --rs-btn-primary-hover-bg: var(--rs-yellow-600);
  --rs-btn-primary-active-bg: var(--rs-yellow-400);
  --rs-btn-subtle-hover-bg: var(--rs-yellow-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-yellow-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-yellow-500);
  --rs-btn-ghost-text: var(--rs-yellow-500);
  --rs-btn-ghost-hover-border: var(--rs-yellow-400);
  --rs-btn-ghost-hover-text: var(--rs-yellow-400);
  --rs-btn-ghost-active-border: var(--rs-yellow-200);
  --rs-btn-ghost-active-text: var(--rs-yellow-200);
  --rs-btn-link-text: var(--rs-yellow-500);
  --rs-btn-link-hover-text: var(--rs-yellow-400);
  --rs-btn-link-active-text: var(--rs-yellow-200);
}
.rs-btn-green {
  --rs-btn-primary-bg: var(--rs-green-500);
  --rs-btn-primary-hover-bg: var(--rs-green-600);
  --rs-btn-primary-active-bg: var(--rs-green-700);
  --rs-btn-subtle-hover-bg: var(--rs-green-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-green-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-green-700);
  --rs-btn-ghost-text: var(--rs-green-700);
  --rs-btn-ghost-hover-border: var(--rs-green-800);
  --rs-btn-ghost-hover-text: var(--rs-green-800);
  --rs-btn-ghost-active-border: var(--rs-green-900);
  --rs-btn-ghost-active-text: var(--rs-green-900);
  --rs-btn-link-text: var(--rs-green-700);
  --rs-btn-link-hover-text: var(--rs-green-800);
  --rs-btn-link-active-text: var(--rs-green-900);
  --rs-iconbtn-primary-addon: var(--rs-green-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-green-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-green-800);
}
.rs-theme-dark .rs-btn-green {
  --rs-btn-primary-bg: var(--rs-green-700);
  --rs-btn-primary-hover-bg: var(--rs-green-600);
  --rs-btn-primary-active-bg: var(--rs-green-400);
  --rs-btn-subtle-hover-bg: var(--rs-green-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-green-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-green-500);
  --rs-btn-ghost-text: var(--rs-green-500);
  --rs-btn-ghost-hover-border: var(--rs-green-400);
  --rs-btn-ghost-hover-text: var(--rs-green-400);
  --rs-btn-ghost-active-border: var(--rs-green-200);
  --rs-btn-ghost-active-text: var(--rs-green-200);
  --rs-btn-link-text: var(--rs-green-500);
  --rs-btn-link-hover-text: var(--rs-green-400);
  --rs-btn-link-active-text: var(--rs-green-200);
  --rs-iconbtn-primary-addon: var(--rs-green-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-green-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-green-400);
}
.rs-theme-high-contrast .rs-btn-green {
  --rs-btn-primary-bg: var(--rs-green-700);
  --rs-btn-primary-hover-bg: var(--rs-green-600);
  --rs-btn-primary-active-bg: var(--rs-green-400);
  --rs-btn-subtle-hover-bg: var(--rs-green-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-green-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-green-500);
  --rs-btn-ghost-text: var(--rs-green-500);
  --rs-btn-ghost-hover-border: var(--rs-green-400);
  --rs-btn-ghost-hover-text: var(--rs-green-400);
  --rs-btn-ghost-active-border: var(--rs-green-200);
  --rs-btn-ghost-active-text: var(--rs-green-200);
  --rs-btn-link-text: var(--rs-green-500);
  --rs-btn-link-hover-text: var(--rs-green-400);
  --rs-btn-link-active-text: var(--rs-green-200);
}
.rs-btn-cyan {
  --rs-btn-primary-bg: var(--rs-cyan-500);
  --rs-btn-primary-hover-bg: var(--rs-cyan-600);
  --rs-btn-primary-active-bg: var(--rs-cyan-700);
  --rs-btn-subtle-hover-bg: var(--rs-cyan-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-cyan-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-cyan-700);
  --rs-btn-ghost-text: var(--rs-cyan-700);
  --rs-btn-ghost-hover-border: var(--rs-cyan-800);
  --rs-btn-ghost-hover-text: var(--rs-cyan-800);
  --rs-btn-ghost-active-border: var(--rs-cyan-900);
  --rs-btn-ghost-active-text: var(--rs-cyan-900);
  --rs-btn-link-text: var(--rs-cyan-700);
  --rs-btn-link-hover-text: var(--rs-cyan-800);
  --rs-btn-link-active-text: var(--rs-cyan-900);
  --rs-iconbtn-primary-addon: var(--rs-cyan-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-cyan-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-cyan-800);
}
.rs-theme-dark .rs-btn-cyan {
  --rs-btn-primary-bg: var(--rs-cyan-700);
  --rs-btn-primary-hover-bg: var(--rs-cyan-600);
  --rs-btn-primary-active-bg: var(--rs-cyan-400);
  --rs-btn-subtle-hover-bg: var(--rs-cyan-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-cyan-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-cyan-500);
  --rs-btn-ghost-text: var(--rs-cyan-500);
  --rs-btn-ghost-hover-border: var(--rs-cyan-400);
  --rs-btn-ghost-hover-text: var(--rs-cyan-400);
  --rs-btn-ghost-active-border: var(--rs-cyan-200);
  --rs-btn-ghost-active-text: var(--rs-cyan-200);
  --rs-btn-link-text: var(--rs-cyan-500);
  --rs-btn-link-hover-text: var(--rs-cyan-400);
  --rs-btn-link-active-text: var(--rs-cyan-200);
  --rs-iconbtn-primary-addon: var(--rs-cyan-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-cyan-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-cyan-400);
}
.rs-theme-high-contrast .rs-btn-cyan {
  --rs-btn-primary-bg: var(--rs-cyan-700);
  --rs-btn-primary-hover-bg: var(--rs-cyan-600);
  --rs-btn-primary-active-bg: var(--rs-cyan-400);
  --rs-btn-subtle-hover-bg: var(--rs-cyan-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-cyan-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-cyan-500);
  --rs-btn-ghost-text: var(--rs-cyan-500);
  --rs-btn-ghost-hover-border: var(--rs-cyan-400);
  --rs-btn-ghost-hover-text: var(--rs-cyan-400);
  --rs-btn-ghost-active-border: var(--rs-cyan-200);
  --rs-btn-ghost-active-text: var(--rs-cyan-200);
  --rs-btn-link-text: var(--rs-cyan-500);
  --rs-btn-link-hover-text: var(--rs-cyan-400);
  --rs-btn-link-active-text: var(--rs-cyan-200);
}
.rs-btn-blue {
  --rs-btn-primary-bg: var(--rs-blue-500);
  --rs-btn-primary-hover-bg: var(--rs-blue-600);
  --rs-btn-primary-active-bg: var(--rs-blue-700);
  --rs-btn-subtle-hover-bg: var(--rs-blue-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-blue-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-blue-700);
  --rs-btn-ghost-text: var(--rs-blue-700);
  --rs-btn-ghost-hover-border: var(--rs-blue-800);
  --rs-btn-ghost-hover-text: var(--rs-blue-800);
  --rs-btn-ghost-active-border: var(--rs-blue-900);
  --rs-btn-ghost-active-text: var(--rs-blue-900);
  --rs-btn-link-text: var(--rs-blue-700);
  --rs-btn-link-hover-text: var(--rs-blue-800);
  --rs-btn-link-active-text: var(--rs-blue-900);
  --rs-iconbtn-primary-addon: var(--rs-blue-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-blue-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-blue-800);
}
.rs-theme-dark .rs-btn-blue {
  --rs-btn-primary-bg: var(--rs-blue-700);
  --rs-btn-primary-hover-bg: var(--rs-blue-600);
  --rs-btn-primary-active-bg: var(--rs-blue-400);
  --rs-btn-subtle-hover-bg: var(--rs-blue-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-blue-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-blue-500);
  --rs-btn-ghost-text: var(--rs-blue-500);
  --rs-btn-ghost-hover-border: var(--rs-blue-400);
  --rs-btn-ghost-hover-text: var(--rs-blue-400);
  --rs-btn-ghost-active-border: var(--rs-blue-200);
  --rs-btn-ghost-active-text: var(--rs-blue-200);
  --rs-btn-link-text: var(--rs-blue-500);
  --rs-btn-link-hover-text: var(--rs-blue-400);
  --rs-btn-link-active-text: var(--rs-blue-200);
  --rs-iconbtn-primary-addon: var(--rs-blue-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-blue-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-blue-400);
}
.rs-theme-high-contrast .rs-btn-blue {
  --rs-btn-primary-bg: var(--rs-blue-700);
  --rs-btn-primary-hover-bg: var(--rs-blue-600);
  --rs-btn-primary-active-bg: var(--rs-blue-400);
  --rs-btn-subtle-hover-bg: var(--rs-blue-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-blue-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-blue-500);
  --rs-btn-ghost-text: var(--rs-blue-500);
  --rs-btn-ghost-hover-border: var(--rs-blue-400);
  --rs-btn-ghost-hover-text: var(--rs-blue-400);
  --rs-btn-ghost-active-border: var(--rs-blue-200);
  --rs-btn-ghost-active-text: var(--rs-blue-200);
  --rs-btn-link-text: var(--rs-blue-500);
  --rs-btn-link-hover-text: var(--rs-blue-400);
  --rs-btn-link-active-text: var(--rs-blue-200);
}
.rs-btn-violet {
  --rs-btn-primary-bg: var(--rs-violet-500);
  --rs-btn-primary-hover-bg: var(--rs-violet-600);
  --rs-btn-primary-active-bg: var(--rs-violet-700);
  --rs-btn-subtle-hover-bg: var(--rs-violet-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-violet-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-violet-700);
  --rs-btn-ghost-text: var(--rs-violet-700);
  --rs-btn-ghost-hover-border: var(--rs-violet-800);
  --rs-btn-ghost-hover-text: var(--rs-violet-800);
  --rs-btn-ghost-active-border: var(--rs-violet-900);
  --rs-btn-ghost-active-text: var(--rs-violet-900);
  --rs-btn-link-text: var(--rs-violet-700);
  --rs-btn-link-hover-text: var(--rs-violet-800);
  --rs-btn-link-active-text: var(--rs-violet-900);
  --rs-iconbtn-primary-addon: var(--rs-violet-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-violet-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-violet-800);
}
.rs-theme-dark .rs-btn-violet {
  --rs-btn-primary-bg: var(--rs-violet-700);
  --rs-btn-primary-hover-bg: var(--rs-violet-600);
  --rs-btn-primary-active-bg: var(--rs-violet-400);
  --rs-btn-subtle-hover-bg: var(--rs-violet-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-violet-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-violet-500);
  --rs-btn-ghost-text: var(--rs-violet-500);
  --rs-btn-ghost-hover-border: var(--rs-violet-400);
  --rs-btn-ghost-hover-text: var(--rs-violet-400);
  --rs-btn-ghost-active-border: var(--rs-violet-200);
  --rs-btn-ghost-active-text: var(--rs-violet-200);
  --rs-btn-link-text: var(--rs-violet-500);
  --rs-btn-link-hover-text: var(--rs-violet-400);
  --rs-btn-link-active-text: var(--rs-violet-200);
  --rs-iconbtn-primary-addon: var(--rs-violet-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-violet-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-violet-400);
}
.rs-theme-high-contrast .rs-btn-violet {
  --rs-btn-primary-bg: var(--rs-violet-700);
  --rs-btn-primary-hover-bg: var(--rs-violet-600);
  --rs-btn-primary-active-bg: var(--rs-violet-400);
  --rs-btn-subtle-hover-bg: var(--rs-violet-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-violet-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-violet-500);
  --rs-btn-ghost-text: var(--rs-violet-500);
  --rs-btn-ghost-hover-border: var(--rs-violet-400);
  --rs-btn-ghost-hover-text: var(--rs-violet-400);
  --rs-btn-ghost-active-border: var(--rs-violet-200);
  --rs-btn-ghost-active-text: var(--rs-violet-200);
  --rs-btn-link-text: var(--rs-violet-500);
  --rs-btn-link-hover-text: var(--rs-violet-400);
  --rs-btn-link-active-text: var(--rs-violet-200);
}
.rs-btn-lg {
  font-size: 16px;
  line-height: 22px;
  padding: 10px 16px;
}
.rs-btn-ghost.rs-btn-lg {
  padding: 9px 15px;
}
.rs-btn-icon.rs-btn-lg {
  padding: 11px 11px;
  line-height: 20px;
}
.rs-btn-icon.rs-btn-lg > .rs-icon {
  font-size: 20px;
}
.rs-btn-icon-with-text.rs-btn-lg {
  line-height: 22px;
}
.rs-btn-icon-with-text.rs-btn-lg > .rs-icon {
  padding: 11px 11px;
  width: 42px;
  height: 42px;
}
.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-left {
  padding: 10px 16px 10px 58px;
}
.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-right {
  padding: 10px 58px 10px 16px;
}
.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: var(--rs-btn-default-border, none);
}
.rs-btn-md {
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
}
.rs-btn-ghost.rs-btn-md {
  padding: 7px 11px;
}
.rs-btn-icon.rs-btn-md {
  padding: 10px 10px;
  line-height: 16px;
}
.rs-btn-icon.rs-btn-md > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-btn-md {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-btn-md > .rs-icon {
  padding: 10px 10px;
  width: 36px;
  height: 36px;
}
.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-left {
  padding: 8px 12px 8px 48px;
}
.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-right {
  padding: 8px 48px 8px 12px;
}
.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: var(--rs-btn-default-border, none);
}
.rs-btn-sm {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px;
}
.rs-btn-ghost.rs-btn-sm {
  padding: 4px 9px;
}
.rs-btn-icon.rs-btn-sm {
  padding: 7px 7px;
  line-height: 16px;
}
.rs-btn-icon.rs-btn-sm > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-btn-sm {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-btn-sm > .rs-icon {
  padding: 7px 7px;
  width: 30px;
  height: 30px;
}
.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-left {
  padding: 5px 10px 5px 40px;
}
.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-right {
  padding: 5px 40px 5px 10px;
}
.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: var(--rs-btn-default-border, none);
}
.rs-btn-xs {
  font-size: 12px;
  line-height: 20px;
  padding: 2px 8px;
}
.rs-btn-ghost.rs-btn-xs {
  padding: 1px 7px;
}
.rs-btn-icon.rs-btn-xs {
  padding: 6px 6px;
  line-height: 12px;
}
.rs-btn-icon.rs-btn-xs > .rs-icon {
  font-size: 12px;
}
.rs-btn-icon-with-text.rs-btn-xs {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-btn-xs > .rs-icon {
  padding: 6px 6px;
  width: 24px;
  height: 24px;
}
.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-left {
  padding: 2px 8px 2px 32px;
}
.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-right {
  padding: 2px 32px 2px 8px;
}
.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: var(--rs-btn-default-border, none);
}
.rs-btn-block {
  display: block;
  width: 100%;
}
.rs-btn-block + .rs-btn-block {
  margin-top: 5px;
}
.rs-btn-loading {
  color: transparent !important;
  position: relative;
  cursor: default;
  pointer-events: none;
}
.rs-btn-loading > .rs-btn-spin::before,
.rs-btn-loading > .rs-btn-spin::after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  z-index: 1;
}
.rs-btn-xs.rs-btn-loading > .rs-btn-spin::before,
.rs-btn-xs.rs-btn-loading > .rs-btn-spin::after {
  width: 16px;
  height: 16px;
}
.rs-btn-loading > .rs-btn-spin::before {
  border: 3px solid var(--rs-loader-ring);
}
.rs-btn-primary.rs-btn-loading > .rs-btn-spin::before {
  border-color: rgba(248, 247, 250, 0.3);
}
.rs-theme-high-contrast .rs-btn-primary.rs-btn-loading > .rs-btn-spin::before {
  border-color: var(--rs-loader-ring-inverse);
}
.rs-btn-loading > .rs-btn-spin::after {
  border-width: 3px;
  border-color: var(--rs-loader-rotor) transparent transparent;
  border-style: solid;
  animation: buttonSpin 0.6s infinite linear;
}
.rs-btn-primary.rs-btn-loading > .rs-btn-spin::after {
  border-top-color: #fff;
}
.rs-theme-high-contrast .rs-btn-primary.rs-btn-loading > .rs-btn-spin::after {
  border-top-color: var(--rs-loader-rotor-inverse);
}
@keyframes buttonSpin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.rs-picker-subtle .picker-subtle-toggle {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block;
  cursor: pointer;
}
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle {
  cursor: not-allowed;
}
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active {
  background: none;
}
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover::after,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus::after,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active::after {
  display: none;
}
.rs-picker-toggle-wrapper {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  width: 100%;
  height: 38px;
}
.rs-picker-toggle.rs-btn .rs-ripple-pond {
  display: none !important;
}
.rs-picker-block {
  display: block;
}
.rs-picker-disabled {
  opacity: 0.3;
}
.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: var(--rs-text-secondary);
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: var(--rs-picker-value);
}
.rs-picker-none {
  padding: 6px 12px 12px;
  color: var(--rs-text-secondary);
  cursor: default;
}
.rs-picker-countable .rs-picker-toggle-value {
  display: flex;
}
.rs-picker-value-list {
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-picker-value-list {
    max-width: 100%;
  }
}
.rs-picker-value-count {
  margin: 0 4px;
  background-color: var(--rs-picker-count-bg);
  color: var(--rs-picker-count-text);
  border-radius: 10px;
  padding: 0 8px;
  line-height: 20px;
}
.rs-picker-value-separator {
  margin: 0 4px 0 0;
}
.rs-picker:not(.rs-picker-disabled):hover,
.rs-picker.rs-picker-focused {
  border-color: var(--rs-input-focus-border);
}
.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  box-shadow: var(--rs-state-focus-shadow);
}
.rs-picker-toggle {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  transition: color 0.2s linear, background-color 0.3s linear;
  border: var(--rs-btn-default-border, none);
  user-select: none;
  text-decoration: none;
  color: var(--rs-btn-default-text);
  background-color: var(--rs-btn-default-bg);
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  overflow: hidden;
  position: relative;
  height: 38px;
}
.rs-theme-high-contrast .rs-picker-toggle {
  transition: none;
}
.rs-btn-ghost.rs-picker-toggle {
  padding: 7px 11px;
}
.rs-btn-icon.rs-picker-toggle {
  padding: 10px 10px;
  line-height: 16px;
}
.rs-btn-icon.rs-picker-toggle > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-picker-toggle {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-picker-toggle > .rs-icon {
  padding: 10px 10px;
  width: 36px;
  height: 36px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-left {
  padding: 8px 12px 8px 48px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-right {
  padding: 8px 48px 8px 12px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: var(--rs-btn-default-border, none);
}
.rs-picker-toggle:focus-visible {
  outline: 3px solid var(--rs-color-focus-ring);
}
.rs-theme-high-contrast .rs-picker-toggle:focus-visible {
  outline-offset: 2px;
}
.rs-picker-toggle:hover,
.rs-picker-toggle:focus {
  color: var(--rs-btn-default-hover-text);
  background-color: var(--rs-btn-default-hover-bg);
  text-decoration: none;
}
.rs-picker-toggle:active,
.rs-picker-toggle.rs-btn-active {
  color: var(--rs-btn-default-active-text);
  background-color: var(--rs-btn-default-active-bg);
}
.rs-picker-toggle:disabled,
.rs-picker-toggle.rs-btn-disabled {
  cursor: not-allowed;
  color: var(--rs-btn-default-disabled-text);
  background-color: var(--rs-btn-default-disabled-bg);
}
.rs-theme-high-contrast .rs-picker-toggle:disabled,
.rs-theme-high-contrast .rs-picker-toggle.rs-btn-disabled {
  opacity: 0.5;
}
@media not all and (min-resolution: 0.001dpcm) {
  .rs-picker-toggle {
    /* stylelint-disable */
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    /* stylelint-enable */
  }
}
.rs-picker-default .rs-picker-toggle {
  transition: border-color ease-in-out 0.3s;
}
.rs-theme-high-contrast .rs-picker-default .rs-picker-toggle {
  transition: none;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  border-color: var(--rs-input-focus-border);
}
.rs-picker-subtle .rs-picker-toggle {
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  transition: none;
}
.rs-picker-subtle .rs-picker-toggle:hover,
.rs-picker-subtle .rs-picker-toggle:focus {
  color: var(--rs-btn-subtle-hover-text);
  background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-picker-subtle .rs-picker-toggle:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-active {
  color: var(--rs-btn-subtle-active-text);
  background-color: var(--rs-btn-subtle-active-bg);
}
.rs-picker-subtle .rs-picker-toggle:disabled,
.rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
  color: var(--rs-btn-subtle-disabled-text);
  background: none;
}
.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle:disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
  opacity: 0.5;
}
.rs-picker-subtle .rs-picker-toggle-active {
  background-color: var(--rs-btn-subtle-hover-bg);
  color: var(--rs-btn-subtle-hover-text);
}
.rs-picker-default .rs-picker-toggle {
  padding: 7px 11px;
}
.rs-picker-toggle-textbox {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: 1px solid #0000;
  padding-left: 10px;
  padding-right: 32px;
  color: var(--rs-text-primary);
  background-color: var(--rs-input-bg);
}
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-textbox {
  padding-left: 14px;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-textbox {
  padding-left: 8px;
}
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-textbox {
  padding-left: 6px;
}
.rs-picker-toggle-read-only {
  opacity: 0;
  pointer-events: none;
}
.rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle {
  transition: border-color ease-in-out 0.3s;
}
.rs-theme-high-contrast .rs-picker-default .rs-btn,
.rs-theme-high-contrast .rs-picker-default .rs-picker-toggle {
  transition: none;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  border-color: var(--rs-input-focus-border);
}
.rs-picker-subtle .rs-btn,
.rs-picker-subtle .rs-picker-toggle {
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  transition: none;
}
.rs-picker-subtle .rs-btn:hover,
.rs-picker-subtle .rs-picker-toggle:hover,
.rs-picker-subtle .rs-btn:focus,
.rs-picker-subtle .rs-picker-toggle:focus {
  color: var(--rs-btn-subtle-hover-text);
  background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-picker-subtle .rs-btn:active,
.rs-picker-subtle .rs-picker-toggle:active,
.rs-picker-subtle .rs-btn.rs-btn-active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-active {
  color: var(--rs-btn-subtle-active-text);
  background-color: var(--rs-btn-subtle-active-bg);
}
.rs-picker-subtle .rs-btn:disabled,
.rs-picker-subtle .rs-picker-toggle:disabled,
.rs-picker-subtle .rs-btn.rs-btn-disabled,
.rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
  color: var(--rs-btn-subtle-disabled-text);
  background: none;
}
.rs-theme-high-contrast .rs-picker-subtle .rs-btn:disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle:disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-btn.rs-btn-disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
  opacity: 0.5;
}
.rs-picker-subtle .rs-btn-active,
.rs-picker-subtle .rs-picker-toggle-active {
  background-color: var(--rs-btn-subtle-hover-bg);
  color: var(--rs-btn-subtle-hover-text);
}
/* stylelint-disable-next-line */
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
  padding-right: 32px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn {
  padding-right: 44px;
}
.rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 8px;
}
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 7px;
}
.rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  right: 12px;
}
.rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  right: 30px;
}
.rs-picker-input:not(.rs-picker-tag)
  .rs-picker-toggle.rs-btn
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding: 7px 12px;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper {
  padding-bottom: 5px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 5px;
}
.rs-picker-tag
  .rs-picker-toggle.rs-btn
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding-left: 12px;
  margin-top: 5px;
  font-size: 14px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper input {
  height: 18px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn {
  padding-top: 7px;
  padding-bottom: 7px;
  box-shadow: none;
}
.rs-picker-date .rs-picker-toggle.rs-btn,
.rs-picker-daterange .rs-picker-toggle.rs-btn {
  padding-right: 36px;
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 8px;
}
.rs-picker-subtle
  .rs-picker-date
  .rs-picker-toggle.rs-btn
  .rs-picker-toggle-clean,
.rs-picker-subtle
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn
  .rs-picker-toggle-clean,
.rs-picker-subtle
  .rs-picker-date
  .rs-picker-toggle.rs-btn
  .rs-picker-toggle-caret,
.rs-picker-subtle
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn
  .rs-picker-toggle-caret {
  top: 7px;
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  right: 12px;
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  right: 30px;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 7px;
  padding-bottom: 7px;
}
.rs-picker-toggle.rs-btn-lg {
  font-size: 16px;
  line-height: 22px;
  padding: 10px 16px;
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-lg {
  padding-right: 36px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-lg {
  padding-right: 50px;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  top: 10px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  top: 9px;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  right: 16px;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  right: 32px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding-left: 15px;
}
.rs-picker-input:not(.rs-picker-tag)
  .rs-picker-toggle.rs-btn-lg
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding: 8px 16px;
  font-size: 16px;
  line-height: 1.375;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper {
  padding-bottom: 6px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 6px;
}
.rs-picker-tag
  .rs-picker-toggle.rs-btn-lg
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding-left: 16px;
  margin-top: 6px;
  font-size: 16px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper input {
  height: 20px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg {
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: none;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg {
  padding-right: 42px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  top: 10px;
}
.rs-picker-subtle
  .rs-picker-date
  .rs-picker-toggle.rs-btn-lg
  .rs-picker-toggle-clean,
.rs-picker-subtle
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn-lg
  .rs-picker-toggle-clean,
.rs-picker-subtle
  .rs-picker-date
  .rs-picker-toggle.rs-btn-lg
  .rs-picker-toggle-caret,
.rs-picker-subtle
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn-lg
  .rs-picker-toggle-caret {
  top: 9px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  right: 16px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  right: 36px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding-top: 9px;
  padding-bottom: 9px;
}
.rs-btn-ghost.rs-picker-toggle.rs-btn-lg {
  padding: 9px 15px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-lg {
  padding: 11px 11px;
  line-height: 20px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-lg > .rs-icon {
  font-size: 20px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg {
  line-height: 22px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg > .rs-icon {
  padding: 11px 11px;
  width: 42px;
  height: 42px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-left {
  padding: 10px 16px 10px 58px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-left
  > .rs-icon {
  left: 0;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-right {
  padding: 10px 58px 10px 16px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-right
  > .rs-icon {
  right: 0;
  border-left: var(--rs-btn-default-border, none);
}
.rs-picker-toggle.rs-btn-lg .rs-picker-value-count {
  line-height: 22px;
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-md {
  padding-right: 32px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-md {
  padding-right: 44px;
}
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  top: 8px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  top: 7px;
}
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  right: 12px;
}
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  right: 30px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-md {
  padding-left: 11px;
}
.rs-picker-input:not(.rs-picker-tag)
  .rs-picker-toggle.rs-btn-md
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding: 7px 12px;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper {
  padding-bottom: 5px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 5px;
}
.rs-picker-tag
  .rs-picker-toggle.rs-btn-md
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding-left: 12px;
  margin-top: 5px;
  font-size: 14px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper input {
  height: 18px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md {
  padding-top: 7px;
  padding-bottom: 7px;
  box-shadow: none;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md {
  padding-right: 36px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  top: 8px;
}
.rs-picker-subtle
  .rs-picker-date
  .rs-picker-toggle.rs-btn-md
  .rs-picker-toggle-clean,
.rs-picker-subtle
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn-md
  .rs-picker-toggle-clean,
.rs-picker-subtle
  .rs-picker-date
  .rs-picker-toggle.rs-btn-md
  .rs-picker-toggle-caret,
.rs-picker-subtle
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn-md
  .rs-picker-toggle-caret {
  top: 7px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  right: 12px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  right: 30px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-md {
  padding-top: 7px;
  padding-bottom: 7px;
}
.rs-picker-toggle.rs-btn-sm {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px;
}
.rs-btn-ghost.rs-picker-toggle.rs-btn-sm {
  padding: 4px 9px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-sm {
  padding: 7px 7px;
  line-height: 16px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-sm > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm > .rs-icon {
  padding: 7px 7px;
  width: 30px;
  height: 30px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-left {
  padding: 5px 10px 5px 40px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-left
  > .rs-icon {
  left: 0;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-right {
  padding: 5px 40px 5px 10px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-right
  > .rs-icon {
  right: 0;
  border-left: var(--rs-btn-default-border, none);
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-sm {
  padding-right: 30px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-sm {
  padding-right: 42px;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  top: 5px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  top: 4px;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  right: 10px;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  right: 28px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm {
  padding-left: 9px;
}
.rs-picker-input:not(.rs-picker-tag)
  .rs-picker-toggle.rs-btn-sm
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding: 4px 10px;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper {
  padding-bottom: 2px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 2px;
}
.rs-picker-tag
  .rs-picker-toggle.rs-btn-sm
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding-left: 10px;
  margin-top: 2px;
  font-size: 14px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper input {
  height: 18px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm {
  padding-top: 4px;
  padding-bottom: 4px;
  box-shadow: none;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm {
  padding-right: 34px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  top: 4px;
}
.rs-picker-subtle
  .rs-picker-date
  .rs-picker-toggle.rs-btn-sm
  .rs-picker-toggle-clean,
.rs-picker-subtle
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn-sm
  .rs-picker-toggle-clean,
.rs-picker-subtle
  .rs-picker-date
  .rs-picker-toggle.rs-btn-sm
  .rs-picker-toggle-caret,
.rs-picker-subtle
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn-sm
  .rs-picker-toggle-caret {
  top: 3px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  right: 10px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  right: 28px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm {
  padding-top: 4px;
  padding-bottom: 4px;
}
.rs-picker-toggle.rs-btn-xs {
  font-size: 12px;
  line-height: 20px;
  padding: 2px 8px;
  /* stylelint-disable */
  /* stylelint-enable */
}
.rs-btn-ghost.rs-picker-toggle.rs-btn-xs {
  padding: 1px 7px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-xs {
  padding: 6px 6px;
  line-height: 12px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-xs > .rs-icon {
  font-size: 12px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs > .rs-icon {
  padding: 6px 6px;
  width: 24px;
  height: 24px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-left {
  padding: 2px 8px 2px 32px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-left
  > .rs-icon {
  left: 0;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-right {
  padding: 2px 32px 2px 8px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-right
  > .rs-icon {
  right: 0;
  border-left: var(--rs-btn-default-border, none);
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-xs {
  padding-right: 28px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-xs {
  padding-right: 40px;
}
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  top: 2px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  top: 1px;
}
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  right: 8px;
}
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  right: 26px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-xs {
  padding-left: 7px;
}
.rs-picker-input:not(.rs-picker-tag)
  .rs-picker-toggle.rs-btn-xs
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding: 1px 8px;
  font-size: 12px;
  line-height: 1.66666667;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper {
  padding-bottom: -1px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: -1px;
}
.rs-picker-tag
  .rs-picker-toggle.rs-btn-xs
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding-left: 8px;
  margin-top: -1px;
  font-size: 12px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper input {
  height: 18px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs {
  padding-top: 1px;
  padding-bottom: 1px;
  box-shadow: none;
}
.rs-picker-input.rs-picker-tag
  .rs-picker-toggle.rs-btn-xs
  ~ .rs-picker-tag-wrapper {
  padding-bottom: 1px;
}
.rs-picker-input.rs-picker-tag
  .rs-picker-toggle.rs-btn-xs
  ~ .rs-picker-tag-wrapper
  .rs-tag {
  margin-top: 1px;
}
.rs-picker-input.rs-picker-tag
  .rs-picker-toggle.rs-btn-xs
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding-top: 1px;
  padding-bottom: 1px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs {
  padding-right: 30px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  top: 2px;
}
.rs-picker-subtle
  .rs-picker-date
  .rs-picker-toggle.rs-btn-xs
  .rs-picker-toggle-clean,
.rs-picker-subtle
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn-xs
  .rs-picker-toggle-clean,
.rs-picker-subtle
  .rs-picker-date
  .rs-picker-toggle.rs-btn-xs
  .rs-picker-toggle-caret,
.rs-picker-subtle
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn-xs
  .rs-picker-toggle-caret {
  top: 1px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  right: 8px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  right: 24px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-xs {
  padding-top: 1px;
  padding-bottom: 1px;
}
/* stylelint-disable-next-line */
.rs-picker-toggle {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.rs-picker-default .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block;
  cursor: pointer;
  color: var(--rs-text-primary);
  border: 1px solid var(--rs-border-primary);
}
.rs-picker-disabled .rs-picker-toggle {
  cursor: not-allowed;
}
.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):hover,
.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):focus {
  border-color: var(--rs-input-focus-border);
}
.rs-picker-subtle .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block;
  cursor: pointer;
}
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle {
  cursor: not-allowed;
}
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active {
  background: none;
}
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover::after,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus::after,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active::after {
  display: none;
}
.rs-picker-toggle-value {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
  padding-right: 44px;
}
.rs-picker-date.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle,
.rs-picker-daterange.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
  padding-right: 52px;
}
.rs-picker-toggle-clean {
  display: flex;
  align-items: center;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 30px;
  height: 20px;
  font-size: 12px;
  background: inherit;
  color: var(--rs-text-secondary);
  transition: 0.2s color linear;
  cursor: pointer;
}
.rs-picker-toggle-clean.rs-btn-close {
  padding: 4px 0;
}
.rs-picker-toggle-clean:hover {
  color: var(--rs-state-error);
}
.rs-picker-date .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle-clean {
  right: 38px;
}
.rs-picker-toggle-caret {
  display: flex;
  align-items: center;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  height: 20px;
  font-size: 12px;
  color: var(--rs-text-secondary);
}
.rs-picker-menu {
  position: absolute;
  text-align: left;
  z-index: 7;
  border-radius: 6px;
  background-color: var(--rs-bg-overlay);
  box-shadow: var(--rs-shadow-overlay);
  overflow: hidden;
  transition: none;
  /* stylelint-disable-next-line */
}
.rs-theme-high-contrast .rs-picker-menu {
  border: 1px solid var(--rs-border-primary);
}
.rs-modal-open .rs-picker-menu {
  z-index: 1055;
}
.rs-drawer-open .rs-picker-menu {
  z-index: 1055;
}
.rs-picker-menu.rs-picker-select-menu,
.rs-picker-menu.rs-picker-check-menu {
  padding-top: 6px;
}
.rs-picker-menu .rs-picker-search-bar {
  position: relative;
  padding: 6px 12px 12px;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  display: block;
  width: 100%;
  color: var(--rs-text-primary);
  background-color: var(--rs-input-bg);
  background-image: none;
  border: 1px solid var(--rs-border-primary);
  transition: border-color ease-in-out 0.3s;
  border-radius: 6px;
  /* stylelint-disable */
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */
  min-width: 200px;
  padding-right: 36px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
    height: 36px;
  }
}
textarea.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  height: auto;
}
.rs-theme-high-contrast
  .rs-picker-menu
  .rs-picker-search-bar
  .rs-picker-search-bar-input {
  transition: none;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::placeholder {
  color: var(--rs-text-secondary);
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus,
.rs-picker-menu
  .rs-picker-search-bar
  .rs-picker-search-bar-input:hover:not(:disabled) {
  border-color: var(--rs-input-focus-border);
  outline: 0;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus {
  box-shadow: var(--rs-state-focus-shadow);
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:disabled {
  background-color: var(--rs-input-disabled-bg);
  color: var(--rs-text-disabled);
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-search-icon {
  position: absolute;
  width: 14px;
  color: var(--rs-text-secondary);
  font-size: 14px;
  height: 20px;
  top: 14px;
  right: 24px;
}
.rs-tree-menu.rs-picker-menu
  .rs-picker-search-bar
  .rs-picker-search-bar-search-icon,
.rs-check-tree-menu.rs-picker-menu
  .rs-picker-search-bar
  .rs-picker-search-bar-search-icon {
  top: 7px;
}
.rs-picker-menu .ReactVirtualized__Grid.ReactVirtualized__List:focus {
  outline: none;
}
.rs-picker-menu.rs-picker-inline {
  position: relative;
  box-shadow: none;
}
.rs-picker-default .rs-btn,
.rs-picker-input .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-picker-toggle {
  background-color: var(--rs-input-bg) !important;
}
.rs-picker-default.rs-picker-disabled .rs-picker-toggle,
.rs-picker-input.rs-picker-disabled .rs-picker-toggle,
.rs-picker-default.rs-picker-disabled .rs-btn,
.rs-picker-input.rs-picker-disabled .rs-btn {
  background-color: var(--rs-input-disabled-bg) !important;
}
.rs-check-item {
  display: block;
}
.rs-check-item:not(.rs-checkbox-disabled):hover,
.rs-check-item:focus,
.rs-check-item.rs-check-item-focus {
  background-color: var(--rs-listbox-option-hover-bg);
}
.rs-theme-high-contrast .rs-check-item:not(.rs-checkbox-disabled):hover,
.rs-theme-high-contrast .rs-check-item:focus,
.rs-theme-high-contrast .rs-check-item.rs-check-item-focus {
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline;
}
.rs-theme-high-contrast
  .rs-theme-high-contrast
  .rs-check-item:not(.rs-checkbox-disabled):hover,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-check-item:focus,
.rs-theme-high-contrast
  .rs-theme-high-contrast
  .rs-check-item.rs-check-item-focus {
  outline-offset: 2px;
}
.rs-theme-high-contrast
  .rs-check-item:not(.rs-checkbox-disabled):hover
  .rs-check-tree-node-text-wrapper,
.rs-theme-high-contrast .rs-check-item:focus .rs-check-tree-node-text-wrapper,
.rs-theme-high-contrast
  .rs-check-item.rs-check-item-focus
  .rs-check-tree-node-text-wrapper {
  text-decoration: underline;
}
.rs-check-item .rs-checkbox-checker {
  padding: 0;
}
.rs-check-item .rs-checkbox-checker > label {
  position: relative;
  cursor: pointer;
  display: block;
  padding: 8px 12px 8px 38px;
  transition: color 0.3s linear, background-color 0.3s linear;
  line-height: 20px;
  /* stylelint-disable-next-line */
}
.rs-theme-high-contrast .rs-check-item .rs-checkbox-checker > label {
  transition: none;
}
.rs-check-item .rs-checkbox-checker > label::before {
  background-color: var(--rs-bg-overlay);
}
.rs-theme-high-contrast .rs-check-item .rs-checkbox-checker > label::before {
  background: none;
}
.ReactVirtualized__Grid .rs-check-item .rs-checkbox-checker > label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rs-checkbox-disabled.rs-check-item .rs-checkbox-checker > label {
  cursor: not-allowed;
}
.rs-check-item .rs-checkbox-checker > label .rs-checkbox-wrapper {
  left: 12px;
}
.grouped .rs-check-item .rs-checkbox-checker > label .rs-checkbox-wrapper,
.rs-picker-check-menu-group-children
  .rs-check-item
  .rs-checkbox-checker
  > label
  .rs-checkbox-wrapper {
  left: 26px;
}
.grouped .rs-check-item .rs-checkbox-checker > label,
.rs-picker-check-menu-group-children
  .rs-check-item
  .rs-checkbox-checker
  > label {
  padding-left: 52px;
}
.rs-picker-date.rs-picker-default .rs-picker-toggle-caret,
.rs-picker-daterange.rs-picker-default .rs-picker-toggle-caret,
.rs-picker-date.rs-picker-default .rs-picker-toggle-clean,
.rs-picker-daterange.rs-picker-default .rs-picker-toggle-clean {
  top: 7px;
}
.rs-picker-toolbar {
  padding: 12px;
  border-top: 1px solid var(--rs-border-primary);
}
.rs-picker-toolbar::before,
.rs-picker-toolbar::after {
  content: ' ';
  display: table;
}
.rs-picker-toolbar::after {
  clear: both;
}
.rs-picker-toolbar-ranges {
  display: inline-block;
}
.rs-picker-toolbar-right {
  float: right;
}
.rs-picker-date-inline {
  height: 299px;
}
.rs-picker-date-inline .rs-calendar {
  height: 286px;
}
.rs-picker-menu .rs-picker-toolbar {
  max-width: 100%;
}
.rs-picker-menu .rs-calendar {
  min-width: 246px;
  display: block;
  margin: 0 auto;
}
.rs-picker-menu .rs-calendar-show-week-numbers {
  min-width: 278px;
}
.rs-picker-menu .rs-calendar-header {
  width: 100%;
}
.rs-picker-menu .rs-calendar-table {
  width: unset;
}
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content,
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  width: 30px;
  height: 30px;
}
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
  background-color: var(--rs-listbox-option-hover-bg);
  color: var(--rs-listbox-option-hover-text);
}
.rs-theme-high-contrast
  .rs-picker-menu
  .rs-calendar
  .rs-calendar-month-dropdown-cell-content:hover {
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline;
}
.rs-theme-high-contrast
  .rs-theme-high-contrast
  .rs-picker-menu
  .rs-calendar
  .rs-calendar-month-dropdown-cell-content:hover {
  outline-offset: 2px;
}
.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-header-row
  .rs-calendar-table-cell-content {
  height: 24px;
  padding-top: 0;
}
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  padding-left: 0;
  padding-right: 0;
  display: inline-block;
}
.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  background-color: var(--rs-listbox-option-hover-bg);
  color: var(--rs-listbox-option-hover-text);
}
.rs-theme-high-contrast
  .rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline;
}
.rs-theme-high-contrast
  .rs-theme-high-contrast
  .rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  outline-offset: 2px;
}
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-scroll {
  height: 227px;
}
.rs-picker-menu .rs-calendar .rs-calendar-time-dropdown-column > ul {
  height: 214px;
  padding-bottom: 184px;
}
.rs-calendar {
  padding-top: 12px;
  position: relative;
  min-height: 266px;
  overflow: hidden;
}
.rs-calendar-bordered .rs-calendar-table {
  border: 1px solid var(--rs-border-primary);
  border-radius: 6px;
}
.rs-calendar-bordered
  .rs-calendar-table-row:not(:last-child)
  .rs-calendar-table-cell,
.rs-calendar-bordered .rs-calendar-table-header-row .rs-calendar-table-cell {
  border-bottom: 1px solid var(--rs-border-secondary);
}
.rs-calendar-bordered .rs-calendar-month-dropdown {
  border: 1px solid var(--rs-border-primary);
  border-radius: 6px;
}
.rs-calendar-panel .rs-calendar-header {
  width: 100%;
  padding-bottom: 6px;
}
.rs-calendar-panel .rs-calendar-header::before,
.rs-calendar-panel .rs-calendar-header::after {
  content: ' ';
  display: table;
}
.rs-calendar-panel .rs-calendar-header::after {
  clear: both;
}
.rs-calendar-panel .rs-calendar-header .rs-calendar-btn-today {
  float: right;
}
.rs-calendar-panel .rs-calendar-header-forward,
.rs-calendar-panel .rs-calendar-header-backward {
  float: none !important;
}
.rs-calendar-panel.rs-calendar-show-month-dropdown .rs-calendar-header-forward,
.rs-calendar-panel.rs-calendar-show-month-dropdown
  .rs-calendar-header-backward {
  display: inline-block;
  visibility: hidden;
  pointer-events: none;
}
.rs-calendar-panel .rs-calendar-header-month-toolbar {
  width: auto !important;
}
.rs-calendar-panel.rs-calendar-show-month-dropdown
  .rs-calendar-header-month-toolbar {
  padding-left: 0;
  padding-right: 0;
}
.rs-calendar-panel .rs-calendar-show-month-dropdown {
  padding-left: 0;
  padding-right: 0;
}
.rs-calendar-panel
  .rs-calendar-table-header-row
  .rs-calendar-table-cell-content {
  padding-top: 8px;
  padding-bottom: 8px;
}
.rs-calendar-panel
  .rs-calendar-table-cell-is-today
  .rs-calendar-table-cell-day {
  color: var(--rs-calendar-today-text);
  width: 20px;
  height: 20px;
  background-color: var(--rs-calendar-today-bg);
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
.rs-calendar-panel
  .rs-calendar-table-cell-is-today
  .rs-calendar-table-cell-content {
  box-shadow: none;
}
.rs-calendar-panel
  .rs-calendar-table-cell-selected
  .rs-calendar-table-cell-content {
  background-color: inherit;
  color: inherit;
  box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
}
.rs-calendar-panel .rs-calendar-table-cell-day {
  margin-top: 3px;
  display: block;
  text-align: center;
}
.rs-calendar-panel .rs-calendar-month-dropdown {
  margin-top: -2px;
  margin-left: 12px;
  margin-right: 12px;
  top: 54px;
  width: calc(100% - 24px);
}
.rs-calendar-panel:not(.rs-calendar-compact)
  .rs-calendar-month-dropdown-scroll {
  height: 644px;
}
.rs-calendar-panel.rs-calendar-compact .rs-calendar-month-dropdown-scroll {
  height: 344px;
}
.rs-calendar-panel:not(.rs-calendar-compact)
  .rs-calendar-table-row:not(.rs-calendar-table-header-row)
  .rs-calendar-table-cell-content {
  width: 100%;
  height: 100px;
  overflow: hidden;
}
.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-cell {
  vertical-align: top;
}
.rs-calendar-panel .rs-calendar-table-cell {
  padding: 0 1px;
  line-height: 0;
}
.rs-calendar-panel .rs-calendar-table-cell-content {
  width: 100%;
  border-radius: 0;
}
.rs-calendar-panel
  .rs-calendar-table-row:last-child
  :first-child
  .rs-calendar-table-cell-content {
  border-bottom-left-radius: 6px;
}
.rs-calendar-panel
  .rs-calendar-table-row:last-child
  :last-child
  .rs-calendar-table-cell-content {
  border-bottom-right-radius: 6px;
}
.rs-calendar-panel.rs-calendar-compact
  .rs-calendar-table-row:not(.rs-calendar-table-header-row)
  .rs-calendar-table-cell-content {
  height: 50px;
}
.rs-calendar-show-month-dropdown .rs-calendar-header-month-toolbar {
  padding-left: 24px;
  padding-right: 24px;
}
.rs-calendar-show-month-dropdown .rs-calendar-header-backward,
.rs-calendar-show-month-dropdown .rs-calendar-header-forward {
  display: none;
}
.rs-calendar-show-month-dropdown .rs-calendar-header-title-date {
  color: var(--rs-text-active);
  background: transparent;
}
.rs-calendar-show-month-dropdown
  .rs-calendar-header-title-date.rs-calendar-header-error {
  color: var(--rs-text-error);
}
.rs-calendar-show-month-dropdown
  .rs-calendar-header-title-date.rs-calendar-header-error:focus,
.rs-calendar-show-month-dropdown
  .rs-calendar-header-title-date.rs-calendar-header-error:hover:active {
  color: #fff !important;
}
.rs-calendar-show-time-dropdown .rs-calendar-header-title-time {
  color: var(--rs-text-active);
  background: transparent;
}
.rs-calendar-show-time-dropdown
  .rs-calendar-header-title-time.rs-calendar-header-error {
  color: var(--rs-text-error);
}
.rs-calendar-show-time-dropdown
  .rs-calendar-header-title-time.rs-calendar-header-error:hover:active {
  color: #fff;
}
.rs-calendar-table-cell-selected,
.rs-calendar-table-cell-in-range {
  position: relative;
}
.rs-calendar-table-cell-selected::before,
.rs-calendar-table-cell-in-range::before {
  content: '';
  display: block;
  width: 100%;
  margin-top: 4px;
  height: 24px;
  position: absolute;
  z-index: 0;
  top: 0;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-cell-in-range .rs-calendar-table-cell-content {
  z-index: 1;
  position: relative;
}
.rs-calendar-table-cell-in-range::before {
  background-color: var(--rs-calendar-range-bg);
}
.rs-calendar-table-cell-in-range:hover::before {
  display: none;
}
.rs-calendar-month-dropdown-scroll div:focus {
  outline: none;
}
.rs-calendar-header {
  width: 280px;
  padding-left: 12px;
  padding-right: 12px;
}
.rs-calendar-header::before,
.rs-calendar-header::after {
  content: ' ';
  display: table;
}
.rs-calendar-header::after {
  clear: both;
}
.rs-calendar-header-month-toolbar,
.rs-calendar-header-time-toolbar {
  display: inline-block;
}
.rs-calendar-header-month-toolbar {
  float: left;
}
.rs-calendar-header-time-toolbar {
  float: right;
}
.rs-calendar-header-title {
  margin: 0 4px;
}
.rs-calendar-header-meridian {
  font-size: 12px;
  margin-left: 4px;
}
.rs-calendar-header-error {
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  --rs-btn-subtle-text: var(--rs-red-500);
  --rs-btn-subtle-hover-bg: var(--rs-red-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-red-600);
  --rs-btn-subtle-active-text: #fff;
}
.rs-calendar-header-error:hover,
.rs-calendar-header-error:focus {
  color: var(--rs-btn-subtle-hover-text);
  background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-calendar-header-error:active,
.rs-calendar-header-error.rs-btn-active {
  color: var(--rs-btn-subtle-active-text);
  background-color: var(--rs-btn-subtle-active-bg);
}
.rs-calendar-header-error:disabled,
.rs-calendar-header-error.rs-btn-disabled {
  color: var(--rs-btn-subtle-disabled-text);
  background: none;
}
.rs-theme-high-contrast .rs-calendar-header-error:disabled,
.rs-theme-high-contrast .rs-calendar-header-error.rs-btn-disabled {
  opacity: 0.5;
}
.rs-theme-dark .rs-calendar-header-error {
  --rs-btn-subtle-hover-bg: var(--rs-red-600);
  --rs-btn-subtle-active-bg: var(--rs-red-400);
}
.rs-calendar-header-btn-disabled,
.rs-calendar-header-btn-disabled:hover,
.rs-calendar-header-btn-disabled:hover:focus {
  cursor: not-allowed;
  opacity: 0.3;
  background: none;
}
.rs-calendar-header-btn-disabled::after {
  display: none !important;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) {
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-month-toolbar {
  display: block;
  text-align: center;
  width: 100%;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-month-toolbar::before,
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-month-toolbar::after {
  content: ' ';
  display: table;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-month-toolbar::after {
  clear: both;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-backward {
  float: left;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-forward {
  float: right;
}
.rs-calendar-header-has-time:not(.rs-calendar-header-has-month) {
  margin: 0 auto;
  text-align: center;
}
.rs-calendar-header-has-time:not(.rs-calendar-header-has-month)
  .rs-calendar-header-time-toolbar {
  float: none;
  display: inline-block;
}
.rs-calendar-header-has-month.rs-calendar-header-has-time {
  margin: 0 auto;
}
/* rtl:begin:ignore */
/* stylelint-disable-next-line */
[dir='rtl'] .rs-calendar-header-backward .rs-icon,
[dir='rtl'] .rs-calendar-header-forward .rs-icon {
  transform: rotate(180deg);
}
/* rtl:end:ignore */
.rs-calendar-view {
  padding: 4px 12px 12px;
}
.rs-calendar-table {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.rs-calendar-table-row {
  display: table-row;
}
.rs-calendar-table-row:nth-child(2) .rs-calendar-table-cell-week-number {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.rs-calendar-table-row:last-child .rs-calendar-table-cell-week-number {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.rs-calendar-table-cell {
  display: table-cell;
  padding: 1px 0;
  text-align: center;
  vertical-align: middle;
}
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover {
  color: var(--rs-text-disabled);
}
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover {
  background: none;
  text-decoration: line-through;
  cursor: not-allowed;
}
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected
  .rs-calendar-table-cell-content:hover {
  color: var(--rs-calendar-date-selected-text);
  background-color: var(--rs-bg-active);
  box-shadow: none;
}
.rs-theme-high-contrast
  .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected
  .rs-calendar-table-cell-content:hover {
  text-decoration: underline;
}
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-selected
  .rs-calendar-table-cell-content {
  opacity: 0.3;
}
.rs-calendar-table-cell-content {
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px;
  cursor: pointer;
  border-radius: 6px;
}
.rs-calendar-table-cell-content:hover {
  background-color: var(--rs-state-hover-bg);
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: var(--rs-calendar-date-selected-text);
  background-color: var(--rs-bg-active);
  box-shadow: none;
}
.rs-theme-high-contrast
  .rs-calendar-table-cell-selected
  .rs-calendar-table-cell-content {
  text-decoration: underline;
}
.rs-calendar-table-header-row .rs-calendar-table-cell-content {
  color: var(--rs-text-secondary);
  font-size: 12px;
  line-height: 1.66666667;
  padding-top: 2px;
  padding-bottom: 2px;
}
.rs-calendar-table-header-row .rs-calendar-table-cell-content,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:focus,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:hover:focus {
  background: none;
  cursor: auto;
}
.rs-calendar-table-cell-week-number {
  display: table-cell;
  width: 1%;
  padding: 1px;
  text-align: center;
  vertical-align: middle;
  color: var(--rs-text-secondary);
  background-color: var(--rs-bg-well);
  font-size: 12px;
}
.rs-calendar-month-dropdown {
  display: none;
  position: absolute;
  top: 44px;
  border-top: 1px solid var(--rs-border-primary);
  margin-top: 0;
  width: 100%;
  background-color: var(--rs-bg-overlay);
  overflow: hidden;
  z-index: 1;
}
.rs-calendar-show-month-dropdown .rs-calendar-month-dropdown {
  display: block;
}
.rs-calendar-show-month-dropdown .rs-calendar-month-dropdown-content {
  animation: 0.3s linear slideDown;
}
.rs-calendar-month-dropdown-scroll {
  height: 230px;
}
.rs-calendar-month-dropdown-row {
  position: relative;
  padding-left: 52px;
  padding-right: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.rs-calendar-month-dropdown-row:not(:last-child) {
  border-bottom: 1px dotted var(--rs-border-primary);
}
.rs-calendar-month-dropdown-year {
  position: absolute;
  top: calc(50% - 0.5em);
  left: 12px;
}
.rs-calendar-month-dropdown-year-active {
  color: var(--rs-text-active);
}
.rs-calendar-month-dropdown-list {
  display: block;
}
.rs-calendar-month-dropdown-list::before,
.rs-calendar-month-dropdown-list::after {
  /* content: ' '; */
  display: table;
}
.rs-calendar-month-dropdown-list::after {
  clear: both;
}
.rs-calendar-month-dropdown-cell {
  display: inline-block;
  float: left;
  width: calc((100% - 1px * 12) / 6);
  margin: 1px;
  text-align: center;
  vertical-align: middle;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-calendar-month-dropdown-cell {
    width: calc((100% - 1px * 12 - 1px) / 6);
  }
}
@supports (-ms-ime-align: auto) {
  .rs-calendar-month-dropdown-cell {
    width: calc((100% - 1px * 12 - 1px) / 6);
  }
}
.rs-calendar-month-dropdown-cell-content {
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px;
  cursor: pointer;
  border-radius: 6px;
}
.rs-calendar-month-dropdown-cell-content:hover {
  background-color: var(--rs-state-hover-bg);
}
.rs-calendar-month-dropdown-cell:not(.rs-calendar-month-dropdown-cell-active).disabled
  .rs-calendar-month-dropdown-cell-content {
  color: var(--rs-text-disabled);
  background: none;
  text-decoration: line-through;
  cursor: not-allowed;
}
.rs-calendar-month-dropdown-cell-active.disabled
  .rs-calendar-month-dropdown-cell-content {
  opacity: 0.3;
  cursor: not-allowed;
}
.rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content {
  color: var(--rs-calendar-date-selected-text);
  background-color: var(--rs-bg-active);
  box-shadow: none;
}
.rs-theme-high-contrast
  .rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content {
  text-decoration: underline;
}
.rs-calendar-time-dropdown {
  display: none;
  position: absolute;
  top: 44px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  background-color: var(--rs-bg-overlay);
  color: var(--rs-text-primary);
  overflow: hidden;
  z-index: 1;
}
.rs-calendar-show-time-dropdown .rs-calendar-time-dropdown {
  display: block;
}
.rs-calendar-show-time-dropdown .rs-calendar-time-dropdown-content {
  animation: 0.3s linear slideDown;
}
.rs-calendar-time-dropdown-row {
  display: table;
  width: 100%;
}
.rs-calendar-time-dropdown-column {
  display: table-cell;
  width: 1%;
}
.rs-calendar-time-dropdown-column > ul,
.rs-calendar-time-dropdown-column > ul > li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.rs-calendar-time-dropdown-column > ul {
  height: 230px;
  overflow-y: auto;
  padding-bottom: 200px;
}
.rs-calendar-time-dropdown-column-title {
  width: 100%;
  background-color: var(--rs-calendar-time-unit-bg);
  color: var(--rs-text-secondary);
  text-align: center;
  font-size: 12px;
  line-height: 1.66666667;
  padding-top: 2px;
  padding-bottom: 2px;
}
.rs-calendar-time-dropdown-cell {
  font-size: 14px;
  line-height: 20px;
  color: inherit;
  padding: 5px;
  display: block;
  text-align: center;
  cursor: pointer;
}
.rs-calendar-time-dropdown-cell,
.rs-calendar-time-dropdown-cell:hover,
.rs-calendar-time-dropdown-cell:focus {
  text-decoration: none;
}
.rs-calendar-time-dropdown-cell:hover {
  background-color: var(--rs-listbox-option-hover-bg);
  color: var(--rs-listbox-option-hover-text);
}
.rs-theme-high-contrast .rs-calendar-time-dropdown-cell:hover {
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline;
}
.rs-theme-high-contrast
  .rs-theme-high-contrast
  .rs-calendar-time-dropdown-cell:hover {
  outline-offset: 2px;
}
.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
  background-color: var(--rs-bg-active);
  color: var(--rs-calendar-date-selected-text);
}
.rs-theme-high-contrast
  .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
  background-color: var(--rs-listbox-option-hover-bg);
  color: var(--rs-listbox-option-hover-text);
}
.rs-theme-high-contrast
  .rs-theme-high-contrast
  .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline;
}
.rs-theme-high-contrast
  .rs-theme-high-contrast
  .rs-theme-high-contrast
  .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
  outline-offset: 2px;
}
.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-disabled {
  color: var(--rs-text-disabled);
  background: none;
  cursor: not-allowed;
  text-decoration: line-through;
}
.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell-disabled.rs-calendar-time-dropdown-cell {
  opacity: 0.3;
  cursor: not-allowed;
}
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
.rs-picker-daterange-menu .rs-calendar {
  display: inline-block;
  height: 278px;
  padding-bottom: 12px;
}
.rs-picker-daterange-menu .rs-calendar:first-child {
  border-right: 1px solid var(--rs-border-primary);
}
.rs-picker-daterange-menu .rs-calendar-header {
  width: 100%;
  text-align: center;
}
.rs-picker-daterange-menu .rs-calendar-header-month-toolbar {
  float: none;
}
.rs-picker-daterange-menu .rs-calendar-month-dropdown {
  z-index: 1;
}
.rs-picker-daterange-menu .rs-calendar-month-dropdown-list {
  width: 185px;
}
.rs-picker-daterange-menu .rs-picker-toolbar {
  margin-top: 4px;
}
.rs-picker-daterange-menu
  .rs-picker-daterange-panel-show-one-calendar
  .rs-picker-toolbar {
  max-width: 255px;
}
.rs-picker-daterange-menu
  .rs-picker-daterange-panel-show-one-calendar
  .rs-picker-toolbar-ranges {
  width: 190px;
}
.rs-picker-daterange-menu .rs-picker-daterange-calendar-single .rs-calendar {
  border: 0;
  display: block;
  margin: auto;
}
.rs-picker-daterange-header {
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-bottom: 1px solid var(--rs-border-primary);
}
.rs-picker-daterange-calendar-group {
  height: 274px;
  min-width: 492px;
}
