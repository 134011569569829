.simple-statistic {
  margin-bottom: 0px !important;

  .label {
    text-transform: capitalize !important;
  }
}

.LocationInformation {
  display: flex;
  flex-direction: column;
  min-height: 20em;

  .column>.segment {
    margin: 1em 0;
  }

  >.contain {
    flex: 1
  }

  .details .header {
    margin-top: 0;
    margin-bottom: 0.5em;

    label {
      display: block;
    }
  }

  .date-edit-button {
    color: #aaa;
    cursor: pointer;
    margin-left: 5px
  }

  .date-text {
    color: rgba(0, 0, 0, .6);
    display: inline-block;
    font-size: 0.95rem;
    font-weight: normal;
  }

  .assign-location-to-route-button-container {
    position: relative;

    .custom-tooltip {
      background-color: #fff;
      border-radius: 5px;
      bottom: calc(100% + 10px);
      color: #3c3f43;
      font-size: 14px;
      font-weight: 100;
      text-align: center;
      left: 0;
      padding: 15px;
      position: absolute;
      width: 170%;
      box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.15);
      border: 1px solid #ddd;
      z-index: 9999;
    }
  }
}

.Location_Month_Range_Picker {
  .button {

    &:first-child,
    &:nth-child(5) {
      text-align: left;
    }

    &:last-child,
    &:nth-child(3) {
      text-align: right;
    }
  }

  .rmp-popup {
    border-radius: 5px !important;
    top: 40px !important;
  }

  .rmp-pad {
    .rmp-btn {
      border-radius: 5px !important;
    }

    &>div {
      border-bottom: 1px solid #ddd;
      padding-bottom: 5px;

      .rmp-btn {
        margin: 3px !important;
      }
    }

    &>ul .rmp-btn {
      margin: 3px !important;
      width: calc(33.33% - 6px) !important
    }
  }
}