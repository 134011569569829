.Schedule_Calendar__Toolbar {

	.rbc-toolbar {
		justify-content: space-between;

		.rbc-toolbar-label {
			flex-grow: unset;
		}
	}

	.rbc-btn-group {
		display: flex;
	}

	button {
		cursor: pointer;
	}

	.buttons {
		display: flex;
	}

	.rs-picker-toggle-textbox,
	.rs-picker-toggle-placeholder,
	.rs-picker-input-group {
		display: none;
	}

	.date-picker-button {
		margin-right: 10px;
		padding-bottom: 0;
		padding-top: 0;
		text-align: center;

		.rs-picker-toggle-wrapper {
			width: 0;
		}
	}

	.date-picker-button .rs-picker-date .rs-picker-toggle {
		align-items: center;
		background-color: transparent !important;
		border: none !important;
		border-radius: 0;
		box-shadow: none;
		display: flex;
		flex-direction: row;
		height: 100%;
		justify-content: center;
		padding: 0;
		width: 0;

		&:hover {
			border: none !important;
		}

		.rs-picker-toolbar {
			display: block !important;
		}

		svg {
			color: #373a3c;
			display: none;
			position: static;
			margin: 0;
			width: 1.3em;
		}
	}
}