#search-bin-input {
    max-width: 180px;
    margin-left: 10px
}

#filter-bin-type-dropdown {
    min-width: fit-content;
    margin-left: 10px
}

#search-bin-map-button {
    min-width: fit-content;
    margin-left: 10px;
    height: 38px;
}