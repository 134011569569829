.AddLocation_CreateNewBinButton {
  margin-bottom: 20px !important;
}

.AddLocation_BinListItem {
  color: #4183c4;
  cursor: pointer;
}

.text-bold {
  font-weight: bold;
}

.create_location_form_simplified_toggle {
  display: flex;
  margin-bottom: 25px;

  label {
    padding-left: 15px;
  }
}