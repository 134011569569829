.ScheduleCalendar__Segment {

  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-bottom: 0 !important;
  position: relative;

  .rbc-month-header {
    .rbc-header {
      text-transform: uppercase;
      font-weight: 200;
      font-size: 0.7rem;
      text-overflow: inherit;
    }
  }

  .rbc-month-row {
    overflow: visible !important;
  }

  .rbc-day-bg {
    background-color: transparent;
  }

  .rbc-date-cell {
    color: #000;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.2;
  }
}

.ScheduleCalendar__container-small {
  .rbc-month-view {
    border: 0;
  }

  .rbc-header {
    border: 0;
  }

  .rbc-month-header {
    padding: 0.5rem 0;
  }

  .rbc-date-cell {
    text-align: center;
    padding: 0;
    position: relative;

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    span {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .rbc-day-bg+.rbc-day-bg,
  .rbc-month-row+.rbc-month-row {
    border: 0;
  }

  .rbc-row {
    z-index: 10;
  }

  .rbc-row+.rbc-row {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }

  .rbc-row-segment {
    position: relative;
  }
}

.ScheduleCalendar__container-small {
  .rbc-row-content {
    .rbc-row:nth-child(3) {
      display: none;
    }
  }

  .ScheduleCalendar__event {
    position: relative;
    width: 90%;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid transparent;

    &.rbc-selected {
      border: 1px solid transparent;
      background-color: transparent;
    }

    &.rbc-addons-dnd-dragged-events {
      transition: border-width 300ms ease-in-out;
    }
  }
}

.ScheduleCalendar__container-big {
  .rbc-row-content {
    min-height: 42px;
    padding: 0;
  }

  .rbc-month-view {
    border-color: #bdbdbd;
    border-radius: 3px;

    .rbc-header,
    .rbc-day-bg,
    .rbc-month-row {
      border-color: #bdbdbd;
    }

    .rbc-addons-dnd-drag-row .rbc-row-segment:nth-child(2) {
      max-width: 14.2857% !important;
    }
  }

  .rbc-row-segment {
    margin: 1px 0;
    padding: 0 2px;
    transition: 0.2s 0.0s ease-out;

    &:last-child {
      margin-bottom: 2px;
    }
  }

  .ScheduleCalendar__event {
    padding: 1px;

    .Route__RunLink {
      background: #FFF;
      border-radius: 4px;
      color: #000;
      display: block;
      font-weight: 300;
      height: 22px;
      margin: 0 1px;
      padding: 0;
      position: relative;

      &.Route__RunLink-complete {
        color: #FFFFFF;
        background: transparent;
      }

      span {
        font-size: 10px;
        line-height: 1.1;
        position: absolute;
        right: 2px;
        top: -1px;
      }

      strong {
        font-size: 13px;
        line-height: 1.1;
        position: absolute;
        left: 2px;
        bottom: 0px;
      }
    }
  }
}

.calendar-event-popup-content {
  position: relative;

  .tooltip-helper-driver {
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 0;
  }
}