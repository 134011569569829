.competitor_history_list {
  .history_log_date {
    border: none !important
  }
}

.current_competitor_list {
  &.simple_list {
    .current_competitor_list_item {
      margin-left: 10px;
      margin-right: 10px;

      &>.content {
        display: flex;
        flex-direction: row;
        justify-content: left;

        .current_competitor_list_item_title {
          color: rgba(0, 0, 0, .68);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .current_competitor_list_item_labels>* {
          margin-left: 5px;
        }
      }
    }
  }

  &.full_list {
    .current_competitor_list_item {
      align-items: center;
      display: flex;
      flex-direction: row;
      font-weight: bold;
    }

    .current_competitor_list_item_labels>* {
      margin-left: 5px;
    }

    .current_competitor_list_item_title_labels {
      align-items: center;
      display: flex;
      justify-content: start;
      flex-grow: 1;
      overflow: hidden;
    }

    .current_competitor_list_item_title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .current_competitor_list_item_button {
      padding-left: 5px;
    }
  }
}

.competitor_history_list {
  .item.competitor_history_list_item {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .competitor_history_list_item_title_labels {
    align-items: center;
    display: flex;
    justify-content: start;
    flex-grow: 1;
    overflow: hidden;
  }

  .competitor_history_list_item_title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .competitor_history_list_item_labels>* {
    margin-left: 5px !important;
  }

  &.mobile {
    .history_log_date span {
      display: block;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}