$panel-height: 70vh;

.Form__ExistingLocation {
  overflow: hidden;

  .Panel__Result {
    max-height: $panel-height;

    .List__Locations {
      max-height: $panel-height;
      overflow-y: scroll;
    }
  }
}