.Map__container {
  height: calc(100vh - 280px);
  position: relative;
  z-index: 0;

  .leaflet-container {
    height: inherit;
  }
}

.Market__container {
  .Map__container {
    height: calc(100vh - 30px);
  }
}

.Map__marker {
  color: #FFF;
  text-align: center;
  z-index: 1;

  &:before {
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.3);
    content: ' ';
    text-align: center;
    transform: translateX(-50%);
    width: 15px;
    height: 3px;
    position: absolute;
    z-index: 0;
    top: 30px;
    left: 50%;
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, .2);
  }

  >span {
    border-radius: 50% 50% 0 50%;
    background: #f2711c;
    content: ' ';
    text-align: center;
    transform: rotate(45deg);
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 0;
    top: -5px;
    left: 0;
    border: 1px solid rgba(0, 0, 0, .2);

    >span {
      transform: rotate(-45deg);
      position: absolute;
      width: 100%;
      text-align: center;
      left: 5px;
      top: 5px;
      height: 100%;
    }
  }

  >i {
    position: relative;
    margin-left: 3px;
    color: #4b4b4b;
  }
}

.Location__pin {
  >span {
    background: #C0C0C0;
  }
}

.Location__end {
  z-index: 100;

  &:after {
    background-color: #D00;
  }
}

.Location__start {
  z-index: 100;

  &:after {
    background-color: #0D0;
  }
}

.Location__unloading {
  z-index: 100;

  &:after {
    background-color: #EF0;
  }

  >span {
    background-color: #ffd700;
  }

  >i {
    color: #4b4b4b;
  }
}

.Location__selected {
  z-index: 999 !important;

  &:after {
    background-color: #0F2;
  }

  >span {
    background-color: #44BA56;
  }

  >i {
    color: #4b4b4b;
  }
}

.Location__exist {
  z-index: 999 !important;

  &:after {
    background-color: #0F2;
  }

  >span {
    background-color: #2c662d;
  }

  >i {
    color: white;
  }
}

// This needs to be at this `zIndex` for it to show over the map.
.ui.dropdown .menu {
  z-index: 500;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  border-radius: 4px !important;
}

.leaflet-popup-content {
  margin: 8px;
  font-size: 14px;

  .card {
    box-shadow: none !important;
  }
}

.leaflet-tooltip-pane {
  .card {
    box-shadow: none !important;
  }
}