$annual_goal_color: #c5cae9;
$quarter_1_color: #f1f8e9;
$quarter_2_color: #f3e5f5;
$quarter_3_color: #fff3e0;
$quarter_4_color: #eceff1;
$errorColor: #e57373;

#monthly-report-messages {
  background-color: #fff !important;
  z-index: unset !important
}

.monthly-data-entry-form {
  thead {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  th {
    text-align: center !important;

    &.annual_goal {
      background-color: $annual_goal_color !important;
    }

    &.quarter_1 {
      background-color: $quarter_1_color !important;
    }

    &.quarter_2 {
      background-color: $quarter_2_color !important;
    }

    &.quarter_3 {
      background-color: $quarter_3_color !important;
    }

    &.quarter_4 {
      background-color: $quarter_4_color !important;
    }
  }

  td {
    text-align: center !important;

    .label {
      font-size: 14px;
    }

    &.route {
      text-align: center;

      &.quarter_1 {
        background-color: lighten($color: $quarter_1_color, $amount: 4) !important;
      }

      &.quarter_2 {
        background-color: lighten($color: $quarter_2_color, $amount: 4) !important;
      }

      &.quarter_3 {
        background-color: lighten($color: $quarter_3_color, $amount: 4) !important;
      }

      &.quarter_4 {
        background-color: lighten($color: $quarter_4_color, $amount: 4) !important;
      }
    }
  }

  tr td:first-child,
  tr th:first-child {
    border-right: 1px solid #d9dadb;
    background: #fff;
    text-align: center;
    z-index: 2;
  }

  tr th:first-child {
    position: sticky;
    left: 0;
    top: 0;
  }

  tbody tr td:first-child {
    left: 0;
    position: sticky;
  }

  tr th:nth-child(2) {
    border-left: none !important;
  }

  .input {
    &.labeled {
      display: flex;
      height: 38px;

      .label {
        flex-grow: 1;
        text-align: center;

        &:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    &.disabled {
      opacity: 0.85;
    }

    input {
      flex-grow: 1;
      padding-left: 5px !important;
      padding-right: 5px !important;
      text-align: center;
      max-width: 70px;
    }
  }

  .label {
    background: none !important;
    color: #222;

    &.quarter-total {
      font-size: 14px;
      min-width: 50px;
      padding: 11px;
      text-align: center;
    }
  }
}