.RadioButton {
  padding: 0em !important;
  width: 32px;
  height: 32px;
}

.FieldAsOne {
  display: flex;
  .field {
    padding-left: 0.1em !important;
    padding-right: 0.1em !important;
    flex: 1;
  }
  .field:first-child {
    padding-left: '' !important;
  }
  .field:last-child {
    padding-right: '' !important;
  }
}