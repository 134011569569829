// Adding the border style
.ui.menu {
  .active.item {
    border-bottom: solid 4px #f48e5c;
  }
}

.Reports__Filters {
  display: flex;

  .DatePicker__container {
    right: 0;
  }
}