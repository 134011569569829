.RouteForm__Row {
	div {
		&:first-child {
			padding-left: 0px;
		}

		&:last-child {
			padding-right: 0px;
		}
	}

	.hidden {
		display: none !important;
	}
}

.RouteFormRow__Row-center {
	text-align: center;
}