.label {
	display: inline-block;
	font-weight: bold;
	margin-right: 5px;
	margin-bottom: 3px;
}

.reschedule-run-message.message {
	box-shadow: unset !important;
	background-color: unset !important;
}