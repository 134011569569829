.DatePicker__container {
  overflow: hidden;
  transition-duration: 500ms;
  height: 0px;
  width: 560px;
  position: absolute;
  z-index: 99;
}

.DatePicker__picker {
  text-align: center;
}
