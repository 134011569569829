.RouteLocationsTable__sequence {
  >span {
    display: block;
    color: #FFFFFF;
    background-color: #f2711c;
    border-radius: 50%;
    width: 2.4rem;
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
  }
}

.to-be-removed {
  opacity: 0.35 !important;

  &.RouteLocationsTable__sequence>span>span {
    display: none;
  }
}