html, body {
  height: 100%;
  background-color: #ebebeb;
}

div.firebase-emulator-warning {
  display: none;
}

.app-layout__container {
  padding-bottom: 40px;
  
  > .row {
    > div {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
}

.left-nav__grid {
  background: #FFFFFF;
  font-size: 10px;

  > div {
    padding: 16px;
    text-align: center;
  }
  .icon {
    margin-right: 0;
  }

  a {
    color: #999;
  }

  .row {
    transition: all 300ms ease-in-out;
  }
}

.left-nav__active {
  border-left: 3px solid #f26521;
  a {
    color: #f26521;
  }
}

.left-nav__logout {
  margin-top: 0.5rem;
  padding-top: 20px;
  border-top: 1px solid #f3f3f3;
}

.app-layout__container.full-width {
  width: 100%;

  .container {
    width: 100%;
  }

  .nav-column, .left-nav__grid {
    width: 100px;
  }

  .content-column { 
    flex: 1;
  }
}