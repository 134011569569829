$quarter_total_color: #c5cae9;
$quarter_1_color: #eaf5fc;
$quarter_2_color: #f1f8e9;
$quarter_3_color: #f3e5f5;
$quarter_4_color: #fff3e0;

.cra-route-report-table-container {
  margin-top: 25px;
  margin-bottom: 25px;

  table {
    background-color: #fff;
    border-collapse: collapse;
  }

  thead tr:first-child th:first-child {
    background-color: #fff !important;
    left: 0;
    min-width: 100px;
    position: sticky;
    z-index: 2 !important;
  }

  thead th {
    border-right: 1px solid #d9dadb;
    border-left: none !important;
    text-align: center !important;
    z-index: 1;

    &.header-q1 {
      background-color: $quarter_1_color !important;
    }

    &.header-q2 {
      background-color: $quarter_2_color !important;
    }

    &.header-q3 {
      background-color: $quarter_3_color !important;
    }

    &.header-q4 {
      background-color: $quarter_4_color !important;
    }

    &.header-quarter-all {
      background-color: lighten($color: $quarter_total_color, $amount: 2) !important;
    }
  }

  tbody td {
    border: none;
    border-top: 1px solid #d9dadb;
    font-weight: bold;
    text-align: center !important;
    z-index: 1;

    &:first-child,
    &:first-child:hover {
      background-color: #fff !important;
      left: 0;
      min-width: 100px;
      position: sticky;
      z-index: 2 !important;
    }

    &.title {
      font-weight: normal;
    }

    &.data-q1 {
      background-color: lighten($color: $quarter_1_color, $amount: 3.5) !important;
    }

    &.data-q2 {
      background-color: lighten($color: $quarter_2_color, $amount: 4) !important;
    }

    &.data-q3 {
      background-color: lighten($color: $quarter_3_color, $amount: 4) !important;
    }

    &.data-q4 {
      background-color: lighten($color: $quarter_4_color, $amount: 4) !important;
    }

    &.total-row-q1 {
      background-color: $quarter_1_color;
    }

    &.total-row-q2 {
      background-color: $quarter_2_color;
    }

    &.total-row-q3 {
      background-color: $quarter_3_color;
    }

    &.total-row-q4 {
      background-color: $quarter_4_color;
    }

    &.total-row-q-all {
      background-color: $quarter_total_color;
    }
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 3;
  }
}

#cra-route-report-table-container-print-version {
  background-color: white;
  display: none;
  padding: 10px;

  table {
    border-collapse: collapse;
    border: 1px black solid;

    & td,
    & th {
      border: 1px black solid;
      text-align: center
    }
  }

  .report-title {
    margin-bottom: 0;
  }

  .market-header {
    margin-top: 0;
  }
}

@media print {
  #cra-route-report-table-container-print-version {
    display: block;
  }

  .Reports__Filters__CRA {
    display: none !important
  }

  .cra-route-report-table-container {
    display: none;
  }
}