.SelectField__container #create-new {
    position: sticky;
    bottom: 0;
    background: #fff;
    z-index: 14;
}

.SelectField__container #empty {
    height: 0 !important;
    padding: 0 !important;
}