.Geosuggest__container {
  .geosuggest__suggests-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #FFFFFF;
    z-index: 9999;

    .geosuggest__suggests {
      margin: 0;
      padding: 0;
      border: 1px solid #85b7d9;
      border-radius: .28571429rem;
    }

    .geosuggest__suggests--hidden {
      display: none;
    }

    .geosuggest__item {
      list-style: none none outside;
      padding: .78571429rem 1.14285714rem;
      cursor: pointer;

      &:hover, &.geosuggest__item--active {
        background: rgba(0,0,0,.05);
        color: rgba(0,0,0,.95);
      }
    }
  }
}
