.Bin__Action__Icon {
  color: white;
}

.BinCard {
  margin-bottom: 10px !important;

  &:hover {
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2);
  }

  .content {
    .description {
      margin: 0.7rem;

      .icon {
        margin-right: 0.7rem;
      }
    }

    i.icon {
      margin: 0 0 0 0;
    }
  }

  .header {
    margin-top: 10px !important;

    .BinCard_actions {
      .button {
        position: absolute;
        top: -15px;
        right: -10px;
      }

      .menu {
        left: auto;
        right: -5px;
        top: 30px;
      }
    }
  }

  &.has-color {
    .header {
      .BinCard_actions {

        .button {
          position: absolute;
          top: -45px;
          right: -10px;
        }

        .menu {
          left: auto;
          right: -5px;
          top: 0px;
        }
      }
    }

  }
}

.BinCard_actions {
	float: right;

	a {
		color: black;
		// padding: 11px 16px;
		// display: block;
	}
}