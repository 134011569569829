.Breakdown__Segment {
  padding: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;

  .CollectionsBreakdown__Route__Segment {
    overflow: auto;
  }

  table tbody td,
  table tfoot th {
    &:not(:first-child) {
      border-left: 1px solid #d9dadb;
    }
  }

  table td .row {
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }

}

.multiple.selection.dropdown .default.text {
  color: black
}

@media print {
  .MainHeader__Grid {
    display: none;
  }

  .ui.selection.dropdown {
    border: 0;
    font-size: 20px !important;
    position: absolute;
    left: 0;
    top: 17px;

    .dropdown.icon {
      display: none;
    }
  }

  .ContentHeader__Menu {
    border-bottom: 0 !important;
    height: 72px;

    :not(.MenuDropdown__Menu) {
      display: none;
    }

    .MenuDropdown__Menu {
      position: absolute;
      right: 0;
      font-size: 32px !important;
    }
  }

  .left-nav__grid {
    display: none;
  }

  .Breakdown__Segment .row {
    display: inline;
    position: relative;

    &>.col-sm-3>.ui.fluid.card {
      display: inline;
      float: left;
      margin-right: 5px;
      width: 24% !important;

      .content {
        font-size: 0.8em;

        .value {
          font-size: 1.5rem !important;
        }

        .label {
          font-size: 12px !important;
        }
      }
    }
  }

  .ui.divider {
    margin: 2px 0;
    clear: both;
  }

  .CollectionsBreakdown__Segment {
    .CircleMetrics__Row {
      display: inline;

      .col-sm {
        display: inline;
      }
    }

    .CircleMetric__container {
      max-width: unset;
      min-width: unset;

      padding {
        left: 0.5%;
        right: 0.5%;
      }

      width: 22.5%;
    }

    .content:nth-of-type(2) {
      border: 0;
      padding: 0;

      .col-xs-12 {
        padding: 0;
      }
    }

    .table,
    .table th,
    .table td,
    .table tbody,
    .table tr,
    .table tfoot,
    .table thead {
      font-size: 91.9%;
    }
  }
}