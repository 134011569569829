.SearchLocationPanel {
  .geosuggest__input-wrapper {
    height: 38px;
  }

  .grid {
    .column {
      &:last-of-type {
        padding-left: 0;
      }
    }
  }
}