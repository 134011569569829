body {
  margin: 0;
  padding: 0;
}

#root {
  height: 100vh;
}

/* Flexbox Resets */
.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.row > div .ui.card {
  width: 100% !important;
}

/* Reset padding on forms with react-flexbox-grid */
.ui.form > .container > .row {
  margin-bottom: 1rem;
}

.ui.form > .container > .row:last-child {
  margin-bottom: 0;
}

/* Fix for Checkbox */
.ui.toggle.checkbox .box::before,
.ui.toggle.checkbox label::before {
  background-color: rgba(0, 0, 0, 0.15);
  border: none;
}
