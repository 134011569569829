.ReportCard__Statistic {
  font-size: 12px;

  .value {
    margin-bottom: 5px !important;
  }

  .label {
    text-transform: none !important;
    font-size: 15px !important;
    text-align: left !important;
    color: #91ad98 !important;

    position: absolute;
    bottom: 0;
    right: 5px;
  }
}