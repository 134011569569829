.User__Card {
  margin-bottom: 10px !important;

  .Icon__Options {
    float: right;
  }

}
.User__Card__Icon__Action {
  color: white;
}

.User__Card {
  .User_Dots {
    float: right;

    i.tiny.icon,
    i.tiny.icons {
      font-size: .3em;
    }
  }
  .content {
    .description {
      margin: 0.7rem;

      .icon {
        margin-right: 0.7rem;
      }
    }
    i.icon {
      margin: 0 0 0 0;
    }
  }
}
