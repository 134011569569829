.Three_Dots__Icon {
  float: right;

  i.tiny.icon,
  i.tiny.icons {
    font-size: .3em;
  }
  i.icon {
    margin: 0 0 0 0;
  }
}