.Reports__Filters {
  display: flex;
  flex-direction: row;

  .MenuDropdown__Menu {
    margin: 0 1em;
    display: inline-block;
    flex-direction: row;
    white-space: nowrap;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #dbdbdb;
    padding: 0.4em 3em 0.4em 1em;
  }
}

.MenuDropdown__Menu {
  position: relative;

  >.item {
    z-index: 500;
  }

  .dropdown {
    width: 100%;
  }
}

.MenuDropdown__activeLabel {
  position: absolute;
  top: 20px;
  font-size: 0.75rem;
  font-weight: 100;
  color: #999;
  opacity: 0;
  transition: 300ms ease-in-out;
  padding: 0rem 1.5rem;
  text-transform: uppercase;

  &.MenuDropdown__activeLabel--active {
    opacity: 1;
  }
}