.Loading__container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.Loading__Segment {
  width: 175px;
  height: 175px;
  overflow: hidden;

  &:before {
    display: none !important;
  }

  small {
    display: block !important;
    font-size: 1rem !important;
    margin-top: 4rem !important;
    color: #555 !important;
  }
}
