.CircleMetric__container {
  max-width: 124px;
  position: relative;
  min-width: 80%;

  .label {
    text-transform: none !important;
    margin-top: 8px;
  }

  &.ui.statistic {
    div.value {
      text-transform: none !important;
      font-size: 1.8rem !important;
    }
  }

  .CircleMetric__caption {
    position: absolute;
    margin-top: 45px;
    text-align: center;
    width: 100%;
  }

  .CircleMetric__caption-active {
    position: absolute;
    margin-top: 45px;
    text-align: center;
    width: 100%;
  }
}
