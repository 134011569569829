.route-cost-report-table-container {
  margin-top: 25px;
  margin-bottom: 25px;

  .market-title {
    left: 0;
    position: sticky;
  }

  thead th {
    text-align: center !important;
    z-index: 1;

    &:first-child,
    &:first-child:hover {
      left: 0;
      min-width: 100px;
      position: sticky;
      z-index: 2 !important;
    }

    &:first-child:hover {
      background-color: #f2f2f2 !important;
    }
  }

  tbody th {
    background-color: #f9fafb !important;
    border-right: 1px solid #d9dadb;
    border-top: 1px solid #d9dadb;
    text-align: center !important;
    z-index: 1;

    &:first-child,
    &:first-child:hover {
      background-color: #fff !important;
      border-right: none !important;
      left: 0;
      min-width: 100px;
      position: sticky;
      z-index: 2 !important;
    }
  }

  tbody tr:first-child {
    position: sticky;
    top: 0;
  }

  tr.current-year td {
    text-align: center;

    &:first-child {
      background-color: #fff;
      position: sticky;
      left: 0;
      z-index: 1;
    }
  }

  tr:not(.current-year) td {
    text-align: center;
  }

  .current-year-number,
  .previous-year-number {
    display: inline-block;
    font-size: 12px;
    padding-right: 8px;
    position: absolute;
    right: 0;
    transform-origin: 100% 100%;
    transform: rotate(-90deg);
  }

  td .current-year-number {
    bottom: 95%;
  }

  td .previous-year-number {
    bottom: 45%;
  }

  th .current-year-number {
    bottom: 67%;
  }

  th .previous-year-number {
    bottom: 35%;
  }

  .field .ui.input {
    position: relative;
    max-width: 125px;

    &::before {
      position: absolute;
      content: "$";
      top: 9px;
      left: 10px;
    }

    input {
      padding-left: 20px;
    }
  }

  .field.disabled {
    .ui.input {
      opacity: 1;

      input {
        background-color: #fff;
        color: #000 !important;
      }
    }
  }
}

.route-cost-form {
  padding-left: 30px !important;

  .column {
    padding-left: 0 !important;
    padding-right: 10px !important;
  }

  &>.row>.column:nth-child(1) {
    .field {
      label {
        color: #000 !important;
        opacity: 1 !important;
      }

      .ui.input {
        opacity: 1;
      }
    }
  }

  &>.row>.column:nth-child(2) {
    .field {
      label {
        color: #000 !important;
        opacity: 1 !important;
      }
    }
  }
}

.route-cost-form .grid .column:not(:nth-child(4)),
.route-cost-form>.column:nth-child(2) {
  & .field {
    .ui.input {
      position: relative;

      &::before {
        position: absolute;
        content: "$";
        top: 9px;
        left: 10px;
      }
    }
  }

  & input {
    padding-left: 20px;
  }
}