.CreateRunForm__Form {
  [type="number"] {
    text-align: right !important;
  }
}

.CreateRunForm__locations {
  margin-top: 2rem;

  >.row {
    margin: 1rem 0;
    padding: 0 0 1rem;
    border-bottom: 1px solid #EEE;

    &:last-child {
      border-bottom: 0;
    }
  }
}

.RouteLocationsTable__LocationContainer {
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;

  .RouteLocationsTable__LocationTitle {
    align-items: center;
    display: flex;

    .header {
      margin: 0 0 0 15px;
    }
  }

  .RouteLocationsTable__BinContainer {
    margin: 0 0 20px 0 !important
  }
}

.RouteLocationsTable__sequence {
  // display: inline-block;
}

.create_run_form_location_input {
  .menu {
    min-width: 120px !important;
    width: auto !important;

    .item {
      display: flex !important;
      justify-content: space-between;

      .text {
        max-width: 170px;
        min-width: 120px;
        overflow-wrap: break-word;
      }
    }
  }
}