.context-menu {
	min-width: 125px;
	position: absolute;
	z-index: 1901;

	.segment {
		padding: 10px 5px;
	}

	.list .item {
		background-color: #fff;
		color: #000;
		cursor: pointer;
		padding: 7px 5px;
		transition: 0.3s 0s ease-in-out;

		&:last-child,
		&:first-child {
			padding: 7px 5px;
		}

		&:hover {
			background-color: #eee;
		}

		a {
			color: #000
		}
	}
}