.DataTable__SortableContainer {
  background: #FAFAFA;
  border: 1px solid #EEE;

  td {
    // padding: 0.5rem 1rem;
    padding: 0.78571429em 0.78571429em;
    text-align: inherit;
  }
}

.with-tooltip {
  border-bottom: 1px dotted #000;
  display: inline-block;
}