.ReportsResumen__Segment {
  margin: 10px 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ui.statistic>.value {
    font-size: 1.69rem!important;
  }

  .ui.statistic>.label {
    color: #C2C2C2;
  }
}
