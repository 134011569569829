.Select-input {
  input {
    border: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.FormGroup__fix {
  margin: 1em 0 !important;
}

.FormGroup_fix {
  display: block !important;
}

.PoliceReport__ImageContainer {
  display: inline-block;
  position: relative;
  width: 220px;
}

.PoliceReport__DocumentsContainer {
  margin-bottom: 5px;
}

.PoliceReport__ImageContainer__Image,
.PoliceReport__DocumentContainer__Icon {
  max-width: 200px;
  margin-right: 20px;
}

.PoliceReport__ImageContainer__RemoveButton {
  position: absolute;
  top: 6px;
  right: 23px;
}

.PoliceReport__DocumentsContainer__DocumentLink {
  font-size: 16px;
  margin-right: 20px;
}

.PoliceReport__Documents__AddDocumentButton {
  margin-bottom: 20px !important;
}

.ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
  pointer-events: none;
  opacity: 1 !important;
}