.Schedule__View {
  background: #FFFFFF;

  .dropdown {
    margin-top: 10px;
  }

  .Schedule_Run__Button {
    height: 52px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    margin-left: -16px;
    width: calc(100% + 24px) !important;
  }

  .Schedule_Run__Icon {
    float: right;
  }

  .ui.horizontal.list.Schedule__Routes {
    cursor: pointer;
    display: grid;
    grid-template-columns: repeat(6, calc(100% / 6) [col-start]);
    margin: 20px 0 10px 0;
    width: 100%;

    &>.item {
      margin-left: 0;
    }
  }

  .Schedule__RoutesClear__PlaceHolder {
    height: 30px;
  }

  .Schedule__RoutesClear {
    cursor: pointer;
  }
}

.Schedule__RunLink {
  span {
    display: block;
    font-size: 0.8rem;
  }
}