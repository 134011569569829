.reports-button-page {

    .divider {
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .button {
        margin-bottom: 5px;
        margin-top: 5px;
    }
}