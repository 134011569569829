$color: #f26521;

.NavLink__Button {
  background: #FFFFFF;
  color: $color;
  display: inline-block;
  padding: 1rem;
  width: 100px;
  height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 300ms ease-in-out;

  &.NavLink__Button-active {
    box-shadow: inset 5px 0px $color;
  }

  .icon {
    margin-right: 0;
  }
}
