.RunReport__container {
  label {
    display: block;
    margin: 0 0 0.4rem 0;
    font-weight: 400;
    color: #A9A9A9;
    font-size: 0.9rem;
  }
}

.RunReport__details {
  >.row {
    >div {
      margin-bottom: 2rem;
    }
  }

  strong {
    font-size: 1.2rem;
  }
}

.LegendDot__container {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 2em;
}

.LegendItem__container {
  font-size: 1.2rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  color: #A9A9A9;

  >div {
    white-space: nowrap;
  }

  >div:first-child {
    margin-right: 0.3rem;
  }

  >div:last-child {
    color: #000;
  }
}


.NoData__container {
  width: 100%;
  height: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 2rem;
    font-weight: 100;
    color: #d9d9d9;
  }
}

.RunReport__Stops {
  padding: 0 !important;

  >table {
    margin-top: 0 !important;
    border: 0 !important;
  }

  .weight-edited {
    border-bottom: 1px dotted #A9A9A9;
    display: inline-block;
    cursor: help;
  }
}

.RunReport__Header {
  display: flex;
  align-items: center;

  >*:first-child {
    flex-grow: 1;
  }

  .icon {
    cursor: pointer;
    margin-right: 1rem;
  }

  .header {
    margin-bottom: 0 !important;
  }

  .RunReport__routeLink {
    display: flex;
    align-items: center;
    text-decoration: underline;

    >div {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      border-radius: 2em;
    }
  }

  .customized_run_icon {
    margin-left: 10px;
    margin-right: 0;
  }
}