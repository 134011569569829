.Select-input {
  input {
    border: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.FormGroup__fix {
  margin: 1em 0 !important;
}

.MaintenanceRequest__ImageContainer {
  display: inline-block;
  position: relative;
  width: 220px;
}

.MaintenanceRequest__ImageContainer__Image {
  max-width: 200px;
  margin-right: 20px;
}

.MaintenanceRequest__ImageContainer__RemoveButton {
  position: absolute;
  top: 6px;
  right: 23px;
  font-size: 10px;
}

.ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
  pointer-events: none;
  opacity: 1 !important;
}
