.PoliceReportCard {
  margin-bottom: 10px !important;
  border-top-width: 25px !important;
  border-top-style: solid !important;

  &:hover {
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2);
  }

  .content {
    .description {
      margin: 0.7rem;

      .icon {
        margin-right: 0.7rem;
      }
    }
    i.icon {
      margin: 0 0 0 0;
    }
  }
}

.PoliceReportCard_assignedToMe {
  background: rgba(0, 0, 0, 0.15) !important;
}

.PoliceReportCard_actions {
  float: right;

  a {
    color: black;
  }
}
