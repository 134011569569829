.Select-input {
  input {
    border: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.FormGroup__fix {
  margin: 1em 0 !important;
}

.Bin__ImageContainer {
  display: inline-block;
  position: relative;
  width: 220px;
}

.Bin__ImageContainer__Image {
  max-width: 200px;
  margin-right: 20px;
}

.Bin__ImageContainer__RemoveButton {
  position: absolute;
  top: 6px;
  right: 23px;
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  pointer-events: none;
  opacity: 1 !important;
}

.BinForm_LocationLinkButton {
  margin-top: 20px;
}

.ui.error.message {
  position: sticky;
  bottom: 0;
  z-index: 1000;
}