.Modal__MonthPicker {
  background-color: transparent !important;
  box-shadow: none !important;

  .ModalContent {
    min-height: 280px;
    background-color: transparent !important;

    .month-picker {
      margin-left: -10px;
    }
    .month-picker > .rmp-container .rmp-popup.light {
      border: none;
    }
  }
}