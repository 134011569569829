.SelectField__container {
  &.error {
    .Select-control {
      background: #fff6f6;
      border: 1px solid #e0b4b4;
      color: #9f3a38;
      box-shadow: none;
    }
  }
}
