$annual_goal_color: #c5cae9;
$quarter_1_color: #f1f8e9;
$quarter_2_color: #f3e5f5;
$quarter_3_color: #fff3e0;
$quarter_4_color: #eceff1;
$errorColor: #e57373;

.actual-vs-goals-legends-container {
  margin: 10px 10px 30px 10px;

  .list.legends {
    color: #888;

    .legend-title {
      font-weight: bold;
    }

    .legend-description {
      font-style: italic;
    }
  }
}

.actual-vs-goals-table-container {
  .ui.header {
    left: 0;
    position: sticky;
  }

  &+.actual-vs-goals-table-container .ui.header {
    left: 0;
    margin-top: 50px;
    position: sticky;
  }
}

.actual-vs-goals-table {
  thead {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  th {
    text-align: center !important;

    &.annual_goal {
      background-color: $annual_goal_color !important;
    }

    &.quarter_1 {
      background-color: $quarter_1_color !important;
    }

    &.quarter_2 {
      background-color: $quarter_2_color !important;
    }

    &.quarter_3 {
      background-color: $quarter_3_color !important;
    }

    &.quarter_4 {
      background-color: $quarter_4_color !important;
    }
  }

  td {
    text-align: center !important;

    .label {
      font-size: 14px;
    }

    &.route {
      text-align: center;

      &.quarter_1 {
        background-color: lighten($color: $quarter_1_color, $amount: 4) !important;
      }

      &.quarter_2 {
        background-color: lighten($color: $quarter_2_color, $amount: 4) !important;
      }

      &.quarter_3 {
        background-color: lighten($color: $quarter_3_color, $amount: 4) !important;
      }

      &.quarter_4 {
        background-color: lighten($color: $quarter_4_color, $amount: 4) !important;
      }
    }
  }

  tr td:first-child,
  tr th:first-child {
    border-right: 1px solid #d9dadb;
    background: #fff;
    text-align: center;
    z-index: 2;
  }

  tr th:first-child {
    position: sticky;
    left: 0;
    top: 0;
  }

  tbody tr td:first-child {
    left: 0;
    position: sticky;
  }

  tr th:nth-child(2) {
    border-left: none !important;
  }

  .label {
    background: none !important;
    color: #222;

    &.quarter-total {
      font-size: 14px;
      min-width: 50px;
      padding: 11px;
      text-align: center;
    }

    &.label-red {
      color: #db2828
    }

    &.label-green {
      color: #21ba45
    }
  }
}