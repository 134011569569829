.Routes__Row {
  >div {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.Routes__Segment {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

.Routes-header-checkbox {
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 80px;
  margin-left: 10px;
  position: relative;

  .checkbox label {
    margin-top: 5px;
    padding-left: 20px !important;
  }
}