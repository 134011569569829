.RouteCard__container {
  border-top-width: 25px !important;
  border-top-style: solid !important;

  .footer {
  	background-color: #F68E56
  }

  &:hover {
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2);
  }
}
.RunEvent__Row {
  > div {
    padding-left: 0px;
    text-align: center;
  }
}
