.MarketLocation__Card {
  .message {
    display: inline-block !important;
  }

  small {
    color: #999;
    margin-left: 0.5rem;
    font-weight: 300;
  }
}
