$height: 55px;

.MainHeader__name {
  text-align: right;
  min-height: $height;
  padding: 1rem;

  div:first-child {
    display: inline-block;
    text-align: left;

    span {
      color: #777;
      font-weight: 100;
      font-size: 0.9rem;
    }
  }

  .dropdown.icon {
    top: -1rem;
  }

  h3 {
    margin-bottom: 0;
    white-space: nowrap;
  }
}

.MainHeader__Grid {
  text-align: right;
  background-color: #FFFFFF;

  .row > div {
    border-right: 1px solid #DDDDDD;
    height: 100%;

    &:last-child {
      border: 0;
    }
  }
}

.MainHeader__Logo {
  padding: 1rem;
  text-align: center;
  width: 100px;

  img {
    min-width: $height;
    min-height: $height;
    margin: 0 auto;
  }
}
