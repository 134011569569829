.RouteCalendar__Segment {
  .rbc-month-header {
    .rbc-header {
      text-transform: uppercase;
      font-weight: 200;
      font-size: 0.7rem;
      text-overflow: inherit;
    }
  }

  .rbc-toolbar {
    display: block;

    .rbc-btn-group {
      display: flex;
    }

    .rbc-toolbar-label {
      display: block;
      text-align: center;
      padding: 1rem 0 0.5rem;
    }

    button {
      flex: 1;
    }
  }
}

.RouteCalendar__container-small {
  .rbc-month-view {
    border: 0;
  }

  .rbc-header {
    border: 0;
  }

  .rbc-month-header {
    padding: 0.5rem 0;
  }

  .rbc-date-cell {
    text-align: center;
    padding: 0;
    position: relative;

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    span {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .rbc-day-bg+.rbc-day-bg,
  .rbc-month-row+.rbc-month-row {
    border: 0;
  }

  .rbc-month-row {
    overflow: unset;
  }

  .rbc-row {
    z-index: 10;
  }

  .rbc-row+.rbc-row {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }

  .rbc-row-segment {
    position: relative;
  }
}

.RouteCalendar__container-small {
  .rbc-row-content {
    .rbc-row:nth-child(3) {
      display: none;
    }
  }

  .RouteCalendar__event {
    position: relative;
    width: 90%;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid transparent;
    transition: border-width 300ms ease-in-out;

    &.rbc-selected {
      border: 5px solid transparent;
      background-color: transparent;
    }
  }
}

.RouteCalendar__container-big {
  .rbc-row-content {
    min-height: 100px;

    .rbc-row:nth-child(3) {
      /*display: block;*/
    }
  }

  .RouteCalendar__event {
    padding: 1px;

    a {
      color: #000;
      font-weight: 300;
      display: block;
      padding: 0.1rem 0.3rem;
      background: #FFF;
      margin: 3px;
      border-radius: 3px;

      &.Route__RunLink-complete {
        color: #FFFFFF;
        background: transparent;
      }
    }
  }
}