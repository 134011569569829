.rs-picker-date-menu,
.rs-picker-popup {
  -webkit-filter: unset !important;
  filter: unset !important;
  z-index: 1000 !important;
}

.rs-picker-date {
  .rs-picker-toggle {
    border: 1px solid rgba(34, 36, 38, .15) !important;
    box-shadow: unset !important;
    transition: unset !important;

    &:hover {
      border: 1px solid rgba(34, 36, 38, .35) !important;
    }
  }

  .rs-picker-toggle-textbox {
    border: none !important
  }

  .rs-picker-toggle-value {
    color: rgba(0, 0, 0, .87) !important
  }
}

.rs-picker-toolbar {
  display: none !important;
}